import { Dispatch, SetStateAction, useCallback, useState } from 'react';
import useEventListener from './useEventListener';

type Setter<T> = Dispatch<SetStateAction<T>>;
type Remover = () => void;

declare global {
    interface WindowEventMap {
        'local-storage': LocalStorageEvent;
    }
}

/**
 * This is a custom event, used to notifiy changes to a key in the local storage.
 */
class LocalStorageEvent extends Event {
    key: string;

    constructor(key: string, eventInitDict?: EventInit) {
        super('local-storage', eventInitDict);
        this.key = key;
    }
}

/**
 * Wrap useState to persist values in the browser's local storage.
 *
 * **Beware if you store an object without passing an initial state**:
 * The returned state will be *partial* as it cannot be guaranteed that the unfrozen object
 * will be complete.
 *
 * When an initial state is passed, the missing properties will be filled with the ones from it.
 * So in case the structure of the state is modified, it always be valid as long as the
 * initial state is correct.
 */
function useLocalStorage<T>(key: string): [undefined | Partial<T>, Setter<undefined | T>, Remover];
function useLocalStorage<T>(key: string, initialState: T): [T, Setter<T>, Remover];
function useLocalStorage<T>(
    key: string,
    initialState?: T
): [T | typeof initialState, Setter<T | typeof initialState>, Remover] {
    /**
     * Gets the value from the local storage, or the initial value.
     */
    const _resolveValue = useCallback((): T | typeof initialState => {
        try {
            const item = window.localStorage.getItem(key);

            if (!item) {
                return initialState;
            }

            const value = JSON.parse(item);

            // Try to validate the unfrozen state when we can compare with a default value.
            if (initialState && typeof value !== typeof initialState) {
                throw new Error('The state value from the local storage is invalid or corrupted.');
            }

            switch (typeof initialState) {
                case 'object':
                    // Fill the missing properties with the ones from the default value, when we have one.
                    return { ...initialState, ...value };

                default:
                    return value;
            }
        } catch (error) {
            console.warn(error);

            // if it falls here, the value stored is invalid anyway, so clean it up.
            window.localStorage.removeItem(key);

            return initialState;
        }
    }, [key, initialState]);

    /**
     * Initialise the state.
     */
    const [storedValue, setStoredValue] = useState<T | typeof initialState>(_resolveValue());

    /**
     * Sets the value in the state and local storage.
     */
    const setValue: Setter<T | typeof initialState> = useCallback(
        (value) => {
            try {
                const valueToStore = value instanceof Function ? value(storedValue) : value;

                window.localStorage.setItem(key, JSON.stringify(valueToStore));
                setStoredValue(valueToStore);

                // Notify other instances of this hook that the local storage was updated.
                window.dispatchEvent(new LocalStorageEvent(key));
            } catch (error) {
                console.warn(`Error setting localStorage key “${key}”:`, error);
            }
        },
        [key, storedValue]
    );

    /**
     * Removes the value from the local storage and sets its state to undefined.
     */
    const removeValue: Remover = useCallback(() => {
        try {
            window.localStorage.removeItem(key);
            setStoredValue(initialState);
        } catch (error) {
            console.warn(error);
        }
    }, [key, initialState]);

    const handleStorageChange = useCallback(
        (event: LocalStorageEvent | StorageEvent) => {
            // Ignore updates of other keys.
            if (event?.key && event.key !== key) {
                return;
            }

            setStoredValue(_resolveValue());
        },
        [key, _resolveValue]
    );

    // Listen for changes in the localstorage.
    useEventListener('storage', handleStorageChange);
    useEventListener('local-storage', handleStorageChange);

    return [storedValue, setValue, removeValue];
}

export default useLocalStorage;
