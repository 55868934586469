import { stringify } from 'querystring';
import { call, cancelled, put, takeEvery, takeLatest } from 'redux-saga/effects';
import { api } from '../utils';

import { IdentifiedPartial, IDeviceList, IJsonLdCollection } from '../types';
import { normalizeDeviceList } from '../normalizers/device-list';
import { NormalizedEntities } from '../normalizers';
import { set_loading, set, update } from '../store/device-list';
import { alert } from '../store/alerts';

export const GET_DEVICE_LIST_COLLECTION = 'saga/deviceLists/getDeviceListCollection';
export const PUT_DEVICE_LIST = 'saga/deviceLists/putDeviceList';
export const GET_DEVICE_LIST = 'saga/deviceLists/getDeviceList';
export const DELETE_DEVICE_LIST = 'saga/deviceLists/delete';

interface GetDeviceListsList {
    type: typeof GET_DEVICE_LIST_COLLECTION;
    payload: {
        page?: number;
        pageSize?: number;
    };
}

interface GetDeviceList {
    type: typeof GET_DEVICE_LIST;
    payload: number;
}

interface PutDeviceList {
    type: typeof PUT_DEVICE_LIST;
    payload: IdentifiedPartial<IDeviceList>;
}

interface DeleteDeviceList {
    type: typeof DELETE_DEVICE_LIST;
    payload: {
        deviceListId: number;
        page?: number;
        pageSize?: number;
    };
}

export function* getDeviceListCollection(action: GetDeviceListsList): Generator {
    try {
        yield put(set_loading(true));

        const queryParams = yield call(stringify, action.payload);

        const response = yield call(api, `/api/device_lists?${queryParams}`);
        const deviceLists = yield call(
            normalizeDeviceList,
            (response as IJsonLdCollection<IDeviceList>)['hydra:member']
        );

        yield put(
            set({
                byId: (deviceLists as NormalizedEntities<IDeviceList>).entities.byId,
                entities: (deviceLists as NormalizedEntities<IDeviceList>).result,
                count: (response as IJsonLdCollection<IDeviceList>)['hydra:totalItems'],
            })
        );
    } catch (e) {
        yield put(alert((e as Error).message));
    } finally {
        yield put(set_loading(false));
    }
}

export function* getDeviceList(action: GetDeviceList): Generator {
    const abortController = new AbortController();

    try {
        yield put(set_loading(true));

        const response = yield call(api, `/api/device_lists/${action.payload}.json`);
        const deviceList = yield call(normalizeDeviceList, response as IDeviceList);

        yield put(
            update({
                byId: (deviceList as NormalizedEntities<IDeviceList>).entities.byId,
                entities: (deviceList as NormalizedEntities<IDeviceList>).result,
            })
        );
    } catch (e) {
        yield put(alert((e as Error).message));
    } finally {
        if (yield cancelled()) {
            yield abortController.abort();
        }

        yield put(set_loading(false));
    }
}

export function* putDeviceList(action: PutDeviceList): Generator {
    try {
        yield put(set_loading(true));

        const response = yield call(api, `/api/device_lists/${action.payload.id}.json`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(action.payload),
        });
        const deviceList = yield call(normalizeDeviceList, response as IDeviceList);

        yield put(
            update({
                byId: (deviceList as NormalizedEntities<IDeviceList>).entities.byId,
                entities: (deviceList as NormalizedEntities<IDeviceList>).result,
            })
        );

        yield put(alert({ message: 'commons.updated', type: 'success' }));
    } catch (e) {
        yield put(alert((e as Error).message));
    } finally {
        yield put(set_loading(true));
    }
}

export function* deleteDeviceList(action: DeleteDeviceList): Generator {
    try {
        yield call(api, `/api/device_lists/${action.payload.deviceListId}`, {
            method: 'DELETE',
        });

        yield call(
            { fn: getDeviceListCollection, context: undefined },
            {
                type: GET_DEVICE_LIST_COLLECTION,
                payload: {
                    ...action.payload,
                    page: action.payload.page,
                    pageSize: action.payload.pageSize,
                },
            }
        );
    } catch (e) {
        yield put(alert((e as Error).message));
    }
}

function* deviceListSaga(): Generator {
    yield takeLatest(GET_DEVICE_LIST_COLLECTION, getDeviceListCollection);
    yield takeLatest(GET_DEVICE_LIST, getDeviceList);
    yield takeEvery(PUT_DEVICE_LIST, putDeviceList);
    yield takeEvery(DELETE_DEVICE_LIST, deleteDeviceList);
}

export default deviceListSaga;
