import { call, cancelled, put, takeLatest } from 'redux-saga/effects';
import { alert } from '../store/alerts';
import { set_loading, update } from '../store/campaign';
import { ICampaign } from '../types';
import { api } from '../utils';

export const GET_CAMPAIGN = 'saga/campaigns/getCampaign';

interface GetCampaignAction {
    type: typeof GET_CAMPAIGN;
    payload: ICampaign['id'];
}

export function* getCampaign(action: GetCampaignAction): Generator {
    yield put(set_loading(true));

    const abortController = new AbortController();

    try {
        const campaign = (yield call(api, `/api/campaigns/${action.payload}.json`, {
            signal: abortController.signal,
        })) as ICampaign;

        yield put(
            update({
                byId: { [campaign.id]: campaign },
                entities: [campaign.id],
            })
        );
    } catch (e) {
        yield put(alert((e as Error).message));
    } finally {
        if (yield cancelled()) {
            yield abortController.abort();
        }

        yield put(set_loading(false));
    }
}

function* campaignsSaga(): Generator {
    yield takeLatest(GET_CAMPAIGN, getCampaign);
}

export default campaignsSaga;
