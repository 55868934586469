import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import { IIssue, IIssueMetadata } from '../types';
import type { IBaseState } from './actions';
import * as actions from './actions';
import { ConflictData } from '../types/error';

export interface IState extends IBaseState<IIssue> {
    metadata: IIssueMetadata;
    conflict?: ConflictData<IIssue>;
}

const initialState: IState = {
    metadata: {
        severities: [],
        statuses: [],
        types: [],
        assignees: [],
        mentions: [],
        reporters: [],
        configurations: [],
        reviews: [],
        testSteps: [],
        reviewReasons: [],
        scopes: [],
        labels: [],
    },
    byId: {},
    entities: [],
    count: 0,
    loading: false,
};

export const issues = createSlice({
    name: 'issues',
    initialState,
    reducers: {
        ...actions,
        update_metadata: (state: IState, action: PayloadAction<IIssueMetadata>) => {
            state.metadata = { ...state.metadata, ...action.payload };
        },
        set_conflict: (state: IState, action: PayloadAction<ConflictData<IIssue>>) => {
            state.conflict = action.payload;
        },
        ack_conflict: (state: IState) => {
            state.conflict = undefined;
        },
    },
});

export const { update_metadata, update, set_loading, set, remove, ack_conflict, set_conflict } = issues.actions;

export default issues.reducer;
