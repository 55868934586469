import { createSlice } from '@reduxjs/toolkit';
import { IOperatingSystemVersion } from '../types';
import type { IBaseState } from './actions';
import * as actions from './actions';

const initialState: IBaseState<IOperatingSystemVersion> = {
    byId: {},
    entities: [],
    loading: false,
    count: 0,
};

export const osVersion = createSlice({
    name: 'os-version',
    initialState,
    reducers: {
        ...actions,
    },
});

export const { set_loading, set, update, remove } = osVersion.actions;

export default osVersion.reducer;
