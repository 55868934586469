import { createSlice } from '@reduxjs/toolkit';
import { ICampaign } from '../types';
import type { IBaseState } from './actions';
import * as actions from './actions';

const initialState: IBaseState<ICampaign> = {
    byId: {},
    entities: [],
    loading: false,
    count: 0,
};

const campaigns = createSlice({
    name: 'campaigns',
    initialState,
    reducers: {
        ...actions,
    },
});

export const { update, remove, set_loading, set } = campaigns.actions;

export default campaigns.reducer;
