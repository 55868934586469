import { stringify } from 'querystring';
import { call, cancelled, put, select, takeEvery, takeLatest } from 'redux-saga/effects';
import { INormalizedIssue, normalizeIssue } from '../normalizers';
import { alert } from '../store/alerts';
import { ack_conflict, set, set_conflict, set_loading, update, update_metadata } from '../store/issues';
import { IdentifiedPartial, IIssue, IIssueLabel, IIssueMetadata, IJsonLdCollection, Shallow } from '../types';
import { IAttachmentIssue } from '../types/attachment';
import { api, ApiGenericError, download } from '../utils';
import { ConflictData } from '../types/error';

export const GET_ISSUE = 'saga/issues/get';
export const PUT_ISSUE = 'saga/issues/put';
export const PUT_ISSUE_CONFIGURATION_ATTACHMENT = 'saga/issues/putIssueConfigurationAttachment';
export const GET_ISSUE_LIST = 'saga/issues/getList';
export const PUT_ISSUES_LIST = 'saga/issues/putList';
export const GET_ISSUE_METADATA = 'saga/issues/metadata';
export const POST_EXPORT_ISSUES = 'saga/issues/export';
export const POST_ARCHIVE_ISSUES = 'saga/issues/archive';
export const POST_ISSUE_LABEL = 'saga/issues/postIssueLabel';

interface GetIssues {
    type: typeof GET_ISSUE_LIST;
    payload: {
        campaignId: number;
        page?: number;
        pageSize?: number;
    };
}

interface GetIssueMetadata {
    type: typeof GET_ISSUE_METADATA;
    payload: { issue?: number; campaign: number };
}

interface PutIssues {
    type: typeof PUT_ISSUES_LIST;
    payload: Partial<IIssue>[];
}

interface PutIssueConfigurationAttachment {
    type: typeof PUT_ISSUE_CONFIGURATION_ATTACHMENT;
    payload: IdentifiedPartial<IIssue> & { attachments: Partial<IAttachmentIssue | Shallow<IAttachmentIssue>>[] };
}

interface ExportIssues {
    type: typeof POST_EXPORT_ISSUES;
    payload: number[];
}

interface ArchiveIssues {
    type: typeof POST_ARCHIVE_ISSUES;
    payload: number[];
}

interface GetIssue {
    type: typeof GET_ISSUE;
    payload: {
        id: number;
        campaignId: number;
    };
}

interface PutIssue {
    type: typeof PUT_ISSUE;
    payload: IdentifiedPartial<IIssue> & { attachments?: (IAttachmentIssue | Shallow<IAttachmentIssue>)[] };
}

interface PostIssueLabel {
    type: typeof POST_ISSUE_LABEL;
    payload: Partial<Shallow<IIssueLabel>>;
}

export function* getIssueList(action: GetIssues): Generator {
    const abortController = new AbortController();

    try {
        yield put(set_loading(true));

        const queryParams = yield call(stringify, action.payload);
        const response = (yield call(api, `/api/issues?${queryParams}`, {
            signal: abortController.signal,
        })) as IJsonLdCollection<IIssue>;
        const normalizedIssues = (yield call(normalizeIssue, response['hydra:member'])) as INormalizedIssue<IIssue[]>;

        yield put(
            set({
                byId: normalizedIssues.entities.issues,
                entities: normalizedIssues.result,
                count: response['hydra:totalItems'],
            })
        );
    } catch (e) {
        yield put(alert((e as Error).message));
    } finally {
        if (yield cancelled()) {
            yield abortController.abort();
        }

        yield put(set_loading(false));
    }
}

export function* getIssueMetadata(action: GetIssueMetadata): Generator {
    const abortController = new AbortController();

    try {
        const url = action.payload.issue
            ? `/api/campaigns/${action.payload.campaign}/issues/${action.payload.issue}/metadata.json`
            : `/api/campaigns/${action.payload.campaign}/metadata.json`;

        const response = (yield call(api, url, { signal: abortController.signal })) as IIssueMetadata;

        yield put(update_metadata(response));
    } catch (e) {
        yield put(alert((e as Error).message));
    } finally {
        if (yield cancelled()) {
            yield abortController.abort();
        }
    }
}

export function* putIssueList(action: PutIssues): Generator {
    try {
        const response = (yield call(api, `/api/issues`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(action.payload),
        })) as IJsonLdCollection<IIssue>;

        const normalizedIssues = (yield call(normalizeIssue, response['hydra:member'])) as INormalizedIssue<IIssue[]>;

        yield put(
            update({
                byId: normalizedIssues.entities.issues,
                entities: normalizedIssues.result,
            })
        );
        yield put(alert({ message: 'issue.list.updated', type: 'success' }));
    } catch (e) {
        yield put(alert((e as Error).message));
    }
}

export function* getIssue(action: GetIssue): Generator {
    const abortController = new AbortController();

    try {
        yield put(set_loading(true));

        const response = (yield call(
            api,
            `/api/campaigns/${action.payload.campaignId}/issues/${action.payload.id}.json`,
            {
                signal: abortController.signal,
            }
        )) as IIssue;

        const normalizedIssue = (yield call(normalizeIssue, response)) as INormalizedIssue<IIssue>;

        yield put(
            update({
                byId: normalizedIssue.entities.issues,
                entities: [normalizedIssue.result],
            })
        );
    } catch (e) {
        yield put(alert((e as Error).message));
    } finally {
        if (yield cancelled()) {
            yield abortController.abort();
        }

        yield put(set_loading(false));
    }
}

export function* putIssue(action: PutIssue): Generator {
    try {
        yield put(set_loading(true));

        const response = (yield call(api, `/api/issues/${action.payload.id}.json`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ ...action.payload }),
        })) as IIssue;

        const normalizedIssue = (yield call(normalizeIssue, response)) as INormalizedIssue<IIssue>;

        yield put(
            update({
                byId: normalizedIssue.entities.issues,
                entities: [normalizedIssue.result],
            })
        );
        yield put(alert({ message: 'issue.updated', type: 'success' }));
        yield put(ack_conflict());
    } catch (e) {
        if (e instanceof ApiGenericError && e.code === 409) {
            const issue = (yield select((store) => store.issues.byId[action.payload.id])) as IIssue;

            yield call(
                {
                    fn: getIssue,
                    context: undefined,
                },
                {
                    type: GET_ISSUE,
                    payload: { id: issue.id, campaignId: issue.campaign.id },
                }
            );

            const updatedIssue = (yield select((store) => store.issues.byId[action.payload.id])) as IIssue;

            const properties = Object.keys(action.payload) as (keyof IIssue)[];

            const conflicts = properties.reduce(
                (prev, property) => {
                    if (['id', 'updatedAt'].includes(String(property)) || typeof updatedIssue[property] === 'object') {
                        return prev;
                    }

                    const change = action.payload[property];
                    const current = updatedIssue[property];

                    return {
                        byProperty: {
                            ...prev.byProperty,
                            [property]: { current, change },
                        },
                        property: [...prev.property, property],
                    };
                },
                { byProperty: {}, property: [] } as ConflictData<IIssue>
            );

            yield put(set_conflict(conflicts));

            return;
        }

        yield put(alert((e as Error).message));
    } finally {
        yield put(set_loading(false));
    }
}

export function* putIssueConfigurationAttachments(action: PutIssueConfigurationAttachment): Generator {
    yield put(
        update({
            byId: { [action.payload.id]: action.payload },
            entities: [action.payload.id],
        })
    );

    yield call(
        { fn: putIssue, context: undefined },
        {
            type: PUT_ISSUE,
            payload: {
                ...action.payload,
                attachments: action.payload.attachments?.map((attachment) => ({
                    ...attachment,
                })),
            },
        }
    );
}

export function* postExportIssues(action: ExportIssues): Generator {
    try {
        const response = yield call(api, `/api/issues/export/xlsx.json`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(action.payload),
        });

        if (response) {
            const now = new Date().toLocaleString();
            yield call(download, window.URL.createObjectURL(response as Blob), `issues_${now.replace(/ /g, '_')}.xlsx`);
        }
    } catch (e) {
        yield put(alert((e as Error).message));
    }
}

export function* postArchiveIssues(action: ArchiveIssues): Generator {
    try {
        const response = yield call(api, `/api/issues/zip`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(action.payload),
        });

        if (response) {
            const now = new Date().toLocaleString();
            yield call(download, window.URL.createObjectURL(response as Blob), `issues_${now.replace(/ /g, '_')}.zip`);
        }
    } catch (e) {
        yield put(alert((e as Error).message));
    }
}

export function* postIssueLabel(action: PostIssueLabel): Generator {
    try {
        const response = yield call(api, '/api/issues/issue_labels.json', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(action.payload),
        });

        const labels = yield select((store) => store.issues.metadata.labels);

        yield put(
            update_metadata({
                labels: [...(labels as IIssueLabel[]), response as IIssueLabel],
            } as IIssueMetadata)
        );
    } catch (e) {
        yield put(alert((e as Error).message));
    }
}

function* issueSaga(): Generator {
    yield takeLatest(GET_ISSUE, getIssue);
    yield takeEvery(PUT_ISSUE, putIssue);
    yield takeEvery(PUT_ISSUE_CONFIGURATION_ATTACHMENT, putIssueConfigurationAttachments);
    yield takeLatest(GET_ISSUE_LIST, getIssueList);
    yield takeLatest(GET_ISSUE_METADATA, getIssueMetadata);
    yield takeEvery(PUT_ISSUES_LIST, putIssueList);
    yield takeEvery(POST_EXPORT_ISSUES, postExportIssues);
    yield takeEvery(POST_ARCHIVE_ISSUES, postArchiveIssues);
    yield takeEvery(POST_ISSUE_LABEL, postIssueLabel);
}

export default issueSaga;
