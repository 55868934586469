import { normalize, schema } from 'normalizr';
import { NormalizedEntities } from '.';
import { IBaseModel, Shallow } from '../types';

export const normalizeModel = <T extends (IBaseModel | Shallow<IBaseModel>)[] | IBaseModel | Shallow<IBaseModel>>(
    models: T
): NormalizedEntities<IBaseModel> => {
    const modelSchema = new schema.Entity('byId', undefined, {
        processStrategy: (entity: IBaseModel) => entity,
    });

    return normalize(Array.isArray(models) ? models : [models], [modelSchema]);
};
