import { MD5 } from 'crypto-js';
import isObject from 'lodash/isObject';
import { flatten } from './objectUtils';

/**
 * Converts a standard base64 string to a url safe variant.
 */
export const b64ToB64Url = (string: string): string => {
    return string.replace(/\+/g, '-').replace(/\//g, '_').replace(/=+$/, '');
};

/**
 * Create a MD5 hash from the given input.
 */
export const hash = (input: unknown): string => {
    const flat = Array.isArray(input) || isObject(input) ? flatten(input) : input;
    return MD5(String(flat)).toString();
};

/**
 * Returns a random number between the given interval.
 */
export const randomIntFromInterval = (min: number, max: number): number => {
    return Math.floor(Math.random() * (max - min + 1) + min);
};

/**
 * Returns a random string at the given length.
 */
export const generateRandomString = (length: number): string => {
    const allowedChar = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789-._~';
    let text = '';
    for (let i = 0; i < length; i++) {
        text += allowedChar.charAt(Math.floor(Math.random() * allowedChar.length));
    }

    return text;
};
