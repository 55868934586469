import { createSlice } from '@reduxjs/toolkit';
import { IBrowserVersion } from '../types';
import type { IBaseState } from './actions';
import * as actions from './actions';

const initialState: IBaseState<IBrowserVersion> = {
    byId: {},
    entities: [],
    loading: false,
    count: 0,
};

export const browserVersion = createSlice({
    name: 'browser-version',
    initialState,
    reducers: {
        ...actions,
    },
});

export const { set_loading, set, update, remove } = browserVersion.actions;

export default browserVersion.reducer;
