import { createSlice } from '@reduxjs/toolkit';
import { IBaseModel } from '../types';
import type { IBaseState } from './actions';
import * as actions from './actions';

const initialState: IBaseState<IBaseModel> = {
    byId: {},
    entities: [],
    loading: false,
    count: 0,
};

const models = createSlice({
    name: 'models',
    initialState,
    reducers: {
        ...actions,
    },
});

export const { create, update, remove, set_loading, set } = models.actions;

export default models.reducer;
