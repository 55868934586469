import { normalize, schema } from 'normalizr';
import { NormalizedEntities } from '.';
import { IOperatingSystemVersion } from '../types';

export const normalizeOsVersion = <T extends IOperatingSystemVersion | IOperatingSystemVersion[]>(
    osVersions: T
): NormalizedEntities<IOperatingSystemVersion> => {
    const osVersionSchema = new schema.Entity('byId', undefined, {
        processStrategy: (entity: IOperatingSystemVersion) => ({ ...entity }),
    });

    return normalize(Array.isArray(osVersions) ? osVersions : [osVersions], [osVersionSchema]);
};
