import { createSlice } from '@reduxjs/toolkit';
import { IOperatingSystem } from '../types';
import type { IBaseState } from './actions';
import * as actions from './actions';

const initialState: IBaseState<IOperatingSystem> = {
    byId: {},
    entities: [],
    loading: false,
    count: 0,
};

export const operatingSystem = createSlice({
    name: 'operating-system',
    initialState,
    reducers: {
        ...actions,
    },
});

export const { set_loading, set, update, remove } = operatingSystem.actions;

export default operatingSystem.reducer;
