import { stringify } from 'querystring';
import { call, cancelled, put, takeLatest, takeEvery } from 'redux-saga/effects';
import { normalizeBrowserVersion } from '../normalizers/browser-version';
import { api } from '../utils';

import { IBrowserVersion, IJsonLdCollection, IdentifiedPartial } from '../types';
import { NormalizedEntities } from '../normalizers';
import { set_loading, set, update } from '../store/browser-version';
import { alert } from '../store/alerts';

export const GET_BROWSER_VERSION_COLLECTION = 'saga/browserVersion/getBrowserVersionCollection';
export const GET_BROWSER_VERSION = 'saga/browserVersion/getBrowserVersion';
export const PUT_BROWSER_VERSION = 'saga/browserVersion/putBrowserVersion';
export const POST_BROWSER_VERSION = 'saga/browserVersion/postBrowserVersion';
export const DELETE_BROWSER_VERSION = 'saga/browserVersion/deleteBrowserVersion';

interface GetBrowserVersionList {
    type: typeof GET_BROWSER_VERSION_COLLECTION;
    payload: {
        page?: number;
        pageSize?: number;
    };
}

interface GetBrowserVersion {
    type: typeof GET_BROWSER_VERSION;
    payload: number;
}

interface PutBrowserVersion {
    type: typeof PUT_BROWSER_VERSION;
    payload: IdentifiedPartial<IBrowserVersion>;
}

interface PostBrowserVersion {
    type: typeof POST_BROWSER_VERSION;
    payload: {
        browserVersion: IdentifiedPartial<IBrowserVersion>;
        searchParams: {
            page?: number;
            pageSize?: number;
        };
    };
}

interface DeleteBrowserVersion {
    type: typeof DELETE_BROWSER_VERSION;
    payload: {
        browserVersionId: number;
        page?: number;
        pageSize?: number;
    };
}

export function* getBrowserVersionCollection(action: GetBrowserVersionList): Generator {
    try {
        yield put(set_loading(true));

        const queryParams = yield call(stringify, action.payload);

        const response = yield call(api, `/api/browser_versions?${queryParams}`);
        const osVersions = yield call(
            normalizeBrowserVersion,
            (response as IJsonLdCollection<IBrowserVersion>)['hydra:member']
        );

        yield put(
            set({
                byId: (osVersions as NormalizedEntities<IBrowserVersion>).entities.byId,
                entities: (osVersions as NormalizedEntities<IBrowserVersion>).result,
                count: (response as IJsonLdCollection<IBrowserVersion>)['hydra:totalItems'],
            })
        );
    } catch (e) {
        yield put(alert((e as Error).message));
    } finally {
        yield put(set_loading(false));
    }
}

export function* getBrowserVersion(action: GetBrowserVersion): Generator {
    const abortController = new AbortController();

    try {
        yield put(set_loading(true));

        const response = yield call(api, `/api/browser_versions/${action.payload}.json`);

        yield put(
            update({
                byId: (response as NormalizedEntities<IBrowserVersion>).entities.byId,
                entities: (response as NormalizedEntities<IBrowserVersion>).result,
            })
        );
    } catch (e) {
        yield put(alert((e as Error).message));
    } finally {
        if (yield cancelled()) {
            yield abortController.abort();
        }

        yield put(set_loading(false));
    }
}

export function* putBrowserVersion(action: PutBrowserVersion): Generator {
    try {
        yield put(set_loading(true));

        const response = yield call(api, `/api/browser_versions/${action.payload.id}.json`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(action.payload),
        });
        const osVersion = yield call(normalizeBrowserVersion, response as IBrowserVersion);

        yield put(
            update({
                byId: (osVersion as NormalizedEntities<IBrowserVersion>).entities.byId,
                entities: (osVersion as NormalizedEntities<IBrowserVersion>).result,
            })
        );

        yield put(alert({ message: 'browser_versions.updated', type: 'success' }));
    } catch (e) {
        yield put(alert((e as Error).message));
    } finally {
        yield put(set_loading(false));
    }
}

export function* postBrowserVersion(action: PostBrowserVersion): Generator {
    try {
        yield call(api, '/api/browser_versions.json', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(action.payload),
        });

        yield call(
            { fn: getBrowserVersionCollection, context: undefined },
            {
                type: GET_BROWSER_VERSION_COLLECTION,
                payload: {
                    ...action.payload.searchParams,
                },
            }
        );

        yield put(alert({ message: 'browser_versions.created', type: 'success' }));
    } catch (e) {
        yield put(alert((e as Error).message));
    }
}

export function* deleteBrowserVersion(action: DeleteBrowserVersion): Generator {
    try {
        yield call(api, `/api/browser_versions/${action.payload.browserVersionId}`, {
            method: 'DELETE',
        });

        yield call(
            { fn: getBrowserVersionCollection, context: undefined },
            {
                type: GET_BROWSER_VERSION_COLLECTION,
                payload: {
                    ...action.payload,
                    page: action.payload.page,
                    pageSize: action.payload.pageSize,
                },
            }
        );

        yield put(alert({ message: 'commons.updated', type: 'success' }));
    } catch (e) {
        yield put(alert((e as Error).message));
    }
}

function* osVersionSaga(): Generator {
    yield takeLatest(GET_BROWSER_VERSION_COLLECTION, getBrowserVersionCollection);
    yield takeLatest(GET_BROWSER_VERSION, getBrowserVersion);
    yield takeEvery(PUT_BROWSER_VERSION, putBrowserVersion);
    yield takeEvery(POST_BROWSER_VERSION, postBrowserVersion);
    yield takeEvery(DELETE_BROWSER_VERSION, deleteBrowserVersion);
}

export default osVersionSaga;
