import { Alert, Button, Snackbar } from '@mui/material';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useAncestor } from '../../contexts/AncestorContext';
import { useDispatch, useSelector } from '../../store';
import { alert_close } from '../../store/alerts';

export const Notification = (): React.ReactElement => {
    const dispatch = useDispatch();
    const { postMessage } = useAncestor();
    const { t } = useTranslation();
    const { alerts } = useSelector((store) => store.alerts);
    const [alert] = alerts;

    const handleClose = () => {
        if (!alert) {
            return;
        }

        if (typeof alert.action?.callback === 'function') {
            alert?.action?.callback();
        }

        dispatch(alert_close(alert));
    };

    useEffect(() => {
        // Push a notification on Window.parent if present
        if (!alert) {
            return;
        }

        postMessage({
            function: 'showNotification',
            arguments: [t(alert.message), alert?.type],
        });
    }, [t, postMessage, alert]);

    return (
        <Snackbar
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
            }}
            open={!!alert}
            onClose={handleClose}
            autoHideDuration={alert?.duration}
        >
            <Alert
                severity={alert?.type}
                action={
                    <Button color="inherit" size="small" onClick={handleClose}>
                        {t(alert?.action?.label ?? 'Dismiss')}
                    </Button>
                }
            >
                {t(alert?.message ?? '')}
            </Alert>
        </Snackbar>
    );
};
