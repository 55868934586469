import { INormalizedTestSuite } from '../normalizers';
import type {
    ILabel,
    IShallowScope,
    IShallowTestCase,
    IShallowTestStep,
    IShallowTestSuite,
    ITestSuite,
} from '../types';
import { Exception } from '../types/error';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import dayjs from 'dayjs';
import {
    deleteLabel,
    deleteScope,
    deleteTestCase,
    deleteTestStep,
    insertScope,
    insertTestCase,
    insertTestStep,
    updateLabel,
    updateScope,
    updateTestCase,
    updateTestStep,
} from './test-suite-function';

interface IUIState {
    actionTarget?: number;
    isAttachmentsOpen: boolean;
    isAssignLabelsOpen: boolean;
    isCreateLabelOpen: boolean;
    isCreateScopeOpen: boolean;
    isCreateTestCaseOpen: boolean;
    isImportTestSuiteOpen: boolean;
    isInitialised: boolean;
    isLoading: boolean;
    isSidebarOpen: boolean;
    selection: number[];
    matches: number[];
    matchIndex: null | number;
    scrollTo?: number;
    sidebarScrollTo?: number;
}

// Typescript need this interface to be exported.
export interface IState {
    error?: Exception;
    labels: Record<number, ILabel>;
    scopes: Record<number, IShallowScope>;
    testCases: Record<number, IShallowTestCase>;
    testSteps: Record<number, IShallowTestStep>;
    testSuite: IShallowTestSuite;
    ui: IUIState;
}

export const initialTestSuiteState: IState = {
    labels: [],
    scopes: [],
    testCases: [],
    testSteps: [],
    testSuite: {
        createdAt: new Date(),
        labels: [],
        scopes: [],
        updatedAt: new Date(),
        testCases: [],
        id: 0,
        project: 0,
        title: 'Test Suite',
        isEditable: false,
    },
    ui: {
        isAttachmentsOpen: false,
        isAssignLabelsOpen: false,
        isCreateLabelOpen: false,
        isCreateScopeOpen: false,
        isCreateTestCaseOpen: false,
        isImportTestSuiteOpen: false,
        isInitialised: false,
        isLoading: false,
        isSidebarOpen: false,
        selection: [],
        matches: [],
        matchIndex: null,
    },
};

const initialState: IState = initialTestSuiteState;

export const updateState = (updatedState: IState, state: IState) => {
    state.ui = updatedState.ui;
    state.testSuite = updatedState.testSuite;
    state.testSteps = updatedState.testSteps;
    state.testCases = updatedState.testCases;
    state.scopes = updatedState.scopes;
    state.error = updatedState.error;
    state.labels = updatedState.labels;
};

export const testSuite = createSlice({
    name: 'testSuite',
    initialState,
    reducers: {
        testsuite_loader: (state: IState, action: PayloadAction<boolean>) => {
            state.ui.isLoading = action.payload;
        },
        testsuite_toggle_sidebar: (state: IState) => {
            state.ui.isSidebarOpen = !state.ui.isSidebarOpen;
        },
        testsuite_toggle_label_assign: (state: IState) => {
            state.ui.isAssignLabelsOpen = !state.ui.isAssignLabelsOpen;
        },
        testsuite_toggle_attachment_dialog: (state: IState, action: PayloadAction<undefined | IShallowTestStep>) => {
            state.ui.isAttachmentsOpen = !state.ui.isAttachmentsOpen;
            state.ui.actionTarget = action.payload?.id;
        },
        testsuite_toggle_import_dialog: (state: IState) => {
            state.ui.isImportTestSuiteOpen = !state.ui.isImportTestSuiteOpen;
        },
        testsuite_toggle_testcase_dialog: (state: IState, action: PayloadAction<undefined | IShallowTestCase>) => {
            state.ui.isCreateTestCaseOpen = !state.ui.isCreateTestCaseOpen;
            state.ui.actionTarget = action.payload?.id;
        },
        testsuite_toggle_scope_dialog: (state: IState, action: PayloadAction<undefined | IShallowScope>) => {
            state.ui.isCreateScopeOpen = !state.ui.isCreateScopeOpen;
            state.ui.actionTarget = action.payload?.id;
        },
        testsuite_toggle_label_dialog: (state: IState, action: PayloadAction<undefined | ILabel>) => {
            state.ui.isCreateLabelOpen = !state.ui.isCreateLabelOpen;
            state.ui.actionTarget = action.payload?.id;
        },
        testsuite_deselect: (state: IState, action: PayloadAction<number[] | number>) => {
            state.ui.selection = state.ui.selection.filter((id) =>
                Array.isArray(action.payload) ? !action.payload.includes(id) : id !== action.payload
            );
        },
        testsuite_select: (state: IState, action: PayloadAction<number[] | number>) => {
            state.ui.selection = Array.from(new Set(state.ui.selection.concat(action.payload)));
        },
        testsuite_set_matches: (state: IState, action: PayloadAction<number[]>) => {
            state.ui.matches = action.payload;
        },
        testsuite_set_match_index: (state: IState, action: PayloadAction<null | number>) => {
            state.ui.matchIndex = action.payload;
        },
        testsuite_scroll_to: (state: IState, action: PayloadAction<undefined | number>) => {
            state.ui.scrollTo = action.payload;
        },
        testsuite_sidebar_scroll_to: (state: IState, action: PayloadAction<number>) => {
            state.ui.sidebarScrollTo = action.payload;
        },
        testsuite_update: (state: IState, action: PayloadAction<INormalizedTestSuite<ITestSuite>>) => {
            state.labels = action.payload.entities.labels ?? {};
            state.scopes = action.payload.entities.scopes ?? {};
            state.testCases = action.payload.entities.testCases ?? {};
            state.testSteps = action.payload.entities.testSteps ?? {};
            state.testSuite = action.payload.entities.testSuites[action.payload.result];
            state.ui = { ...state.ui, isInitialised: true };
        },
        add_teststep: (state: IState, action: PayloadAction<IShallowTestStep | IShallowTestStep[]>) => {
            updateState(
                Array.isArray(action.payload)
                    ? action.payload.reduce((state, testStep) => insertTestStep(state, testStep), state)
                    : insertTestStep(state, action.payload),
                state
            );
        },
        delete_teststep: (state: IState, action: PayloadAction<number[] | number>) => {
            updateState(
                Array.isArray(action.payload)
                    ? action.payload.reduce((state, id) => deleteTestStep(state, id), state)
                    : deleteTestStep(state, action.payload),
                state
            );
        },
        update_teststep: (state: IState, action: PayloadAction<IShallowTestStep | IShallowTestStep[]>) => {
            updateState(
                Array.isArray(action.payload)
                    ? action.payload.reduce((state, testStep) => updateTestStep(state, testStep), state)
                    : updateTestStep(state, action.payload),
                state
            );
        },
        add_testcase: (
            state: IState,
            action: PayloadAction<{
                testCase: IShallowTestCase;
                testSteps: Record<number, IShallowTestStep>;
            }>
        ) => {
            updateState(insertTestCase(state, action.payload.testCase, action.payload.testSteps), state);
        },
        delete_testcase: (state: IState, action: PayloadAction<number>) => {
            updateState(deleteTestCase(state, action.payload), state);
        },
        update_testcase: (state: IState, action: PayloadAction<IShallowTestCase>) => {
            updateState(updateTestCase(state, action.payload), state);
        },
        add_scope: (state: IState, action: PayloadAction<IShallowScope>) => {
            updateState(insertScope(state, action.payload), state);
        },
        delete_scope: (state: IState, action: PayloadAction<number>) => {
            updateState(deleteScope(state, action.payload), state);
        },
        update_scope: (state: IState, action: PayloadAction<IShallowScope>) => {
            updateState(updateScope(state, action.payload), state);
        },
        add_label: (state: IState, action: PayloadAction<ILabel>) => {
            state.labels = {
                ...state.labels,
                [action.payload.id]: action.payload,
            };
            state.testSuite = {
                ...state.testSuite,
                labels: [...state.testSuite.labels, action.payload.id],
                updatedAt: dayjs().toDate(),
            };
        },
        delete_label: (state: IState, action: PayloadAction<number>) => {
            updateState(deleteLabel(state, action.payload), state);
        },
        update_label: (state: IState, action: PayloadAction<ILabel>) => {
            updateState(updateLabel(state, action.payload), state);
        },
    },
});
// Action creators are generated for each case reducer function
export const {
    testsuite_deselect,
    testsuite_loader,
    testsuite_scroll_to,
    testsuite_select,
    testsuite_sidebar_scroll_to,
    testsuite_set_match_index,
    testsuite_set_matches,
    testsuite_toggle_attachment_dialog,
    testsuite_toggle_import_dialog,
    testsuite_toggle_label_assign,
    testsuite_toggle_label_dialog,
    testsuite_toggle_scope_dialog,
    testsuite_toggle_sidebar,
    testsuite_toggle_testcase_dialog,
    testsuite_update,
    add_teststep,
    update_teststep,
    delete_teststep,
    delete_testcase,
    update_testcase,
    add_testcase,
    delete_scope,
    update_scope,
    add_scope,
    delete_label,
    update_label,
    add_label,
} = testSuite.actions;

export default testSuite.reducer;
