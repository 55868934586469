import { normalize, schema } from 'normalizr';
import { NormalizedEntities } from '.';
import { IIssueLabel } from '../types';

export const normalizeIssueLabel = <T extends IIssueLabel | IIssueLabel[]>(
    labels: T
): NormalizedEntities<IIssueLabel> => {
    const labelSchema = new schema.Entity('byId', undefined, {
        processStrategy: (entity: IIssueLabel) => entity,
    });

    return normalize(Array.isArray(labels) ? labels : [labels], [labelSchema]);
};
