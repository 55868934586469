import { normalize, schema } from 'normalizr';
import { NormalizedEntities } from '.';
import { IBrowserVersion } from '../types';

export const normalizeBrowserVersion = <T extends IBrowserVersion | IBrowserVersion[]>(
    browserVersions: T
): NormalizedEntities<IBrowserVersion> => {
    const BrowserVersionSchema = new schema.Entity('byId', undefined, {
        processStrategy: (entity: IBrowserVersion) => ({ ...entity }),
    });

    return normalize(Array.isArray(browserVersions) ? browserVersions : [browserVersions], [BrowserVersionSchema]);
};
