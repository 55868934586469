import { call, put, takeEvery } from 'redux-saga/effects';
import { INormalizedScope, normalizeScope } from '../normalizers/test-suite';
import { IScope, IShallowScope } from '../types';
import { api } from '../utils';
import { add_scope, delete_scope, update_scope } from '../store/test-suite';
import { alert } from '../store/alerts';

export const DELETE_SCOPE = 'DELETE_SCOPE';
export const POST_SCOPE = 'POST_SCOPE';
export const PUT_SCOPE = 'PUT_SCOPE';

interface DeleteScopeAction {
    type: typeof DELETE_SCOPE;
    payload: IShallowScope;
}

interface PostScopeAction {
    type: typeof POST_SCOPE;
    payload: IScope & { testSuite: number };
}

interface PutScopeAction {
    type: typeof PUT_SCOPE;
    payload: IScope;
}

export function* deleteScope(action: DeleteScopeAction): Generator {
    try {
        yield call(api, `/api/scopes/${action.payload.id}`, {
            method: 'DELETE',
        });

        yield put(delete_scope(action.payload.id));
    } catch (e) {
        yield put(alert((e as Error).message));
    }
}

export function* postScope(action: PostScopeAction): Generator {
    try {
        const response = yield call(api, '/api/scopes.json', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(action.payload),
        });

        const normalizedResponse = yield normalizeScope(response as IScope);

        yield put(
            add_scope(
                (normalizedResponse as INormalizedScope<IScope>).entities.scopes[
                    (normalizedResponse as INormalizedScope<IScope>).result
                ]
            )
        );
    } catch (e) {
        yield put(alert((e as Error).message));
    }
}

export function* putScope(action: PutScopeAction): Generator {
    try {
        const response = yield call(api, `/api/scopes/${action.payload.id}.json`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(action.payload),
        });

        const normalizedResponse = yield normalizeScope(response as IScope);

        yield put(
            update_scope(
                (normalizedResponse as INormalizedScope<IScope>).entities.scopes[
                    (normalizedResponse as INormalizedScope<IScope>).result
                ]
            )
        );
    } catch (e) {
        yield put(alert((e as Error).message));
    }
}

function* scopeSaga(): Generator {
    yield takeEvery(DELETE_SCOPE, deleteScope);
    yield takeEvery(POST_SCOPE, postScope);
    yield takeEvery(PUT_SCOPE, putScope);
}

export default scopeSaga;
