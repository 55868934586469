import { createContext, PropsWithChildren, ReactElement, useCallback, useContext, useEffect, useReducer } from 'react';
import type { IOAuthToken, ILoggedUser } from '../types';
import { OAUTH_TOKEN_STORAGE_KEY } from '../constants';
import useLocalStorage from '../hooks/useLocalStorage';
import { api } from '../utils';

interface InitialiseAction {
    type: 'INITIALISE';
    payload?: {
        isAuthenticated?: boolean;
        isInitialized?: boolean;
        user?: null | ILoggedUser;
    };
}

interface LogoutAction {
    type: 'LOGOUT';
}

type Action = InitialiseAction | LogoutAction;

interface IAuthState {
    isAuthenticated: boolean;
    isInitialized: boolean;
    user: null | ILoggedUser;
}

interface IAuthContext extends IAuthState {
    logout: () => void;
}

/**
 * Default authentication state.
 */
const defaultAuthState: IAuthState = {
    isAuthenticated: false,
    isInitialized: false,
    user: null,
};

/**
 * Handle authentication state in memory storage.
 */
const _reducer = (state: IAuthState, action: Action): IAuthState => {
    switch (action.type) {
        case 'INITIALISE': {
            return {
                ...state,
                ...action.payload,
            };
        }
        case 'LOGOUT': {
            return {
                ...state,
                isAuthenticated: false,
                user: null,
            };
        }

        /* istanbul ignore next */
        default: {
            return { ...state };
        }
    }
};

/**
 * Provide the user's current authentication state.
 */
export const AuthProvider = ({ children }: PropsWithChildren<unknown>): ReactElement => {
    const [state, dispatch] = useReducer(_reducer, defaultAuthState);
    const [token, , removeToken] = useLocalStorage<IOAuthToken>(OAUTH_TOKEN_STORAGE_KEY);

    /**
     * Sets the authentication state as not authenticated, and clean up stored token.
     */
    const logout = useCallback(() => {
        removeToken();
        dispatch({
            type: 'LOGOUT',
        });
    }, [removeToken]);

    useEffect(() => {
        const fetchUser = async () => {
            try {
                dispatch({
                    type: 'INITIALISE',
                    payload: {
                        isInitialized: false,
                    },
                });

                const user = await api<ILoggedUser>('/api/users/me');

                if (user) {
                    dispatch({
                        type: 'INITIALISE',
                        payload: {
                            isAuthenticated: true,
                            isInitialized: true,
                            user,
                        },
                    });
                }
            } catch (e) {
                dispatch({
                    type: 'INITIALISE',
                    payload: {
                        isAuthenticated: false,
                        isInitialized: true,
                        user: null,
                    },
                });
            }
        };

        if (!token?.access_token) {
            dispatch({
                type: 'INITIALISE',
                payload: {
                    isInitialized: true,
                },
            });

            return;
        }

        fetchUser();
    }, [token?.access_token]);

    return (
        <AuthContext.Provider
            value={{
                ...state,
                logout,
            }}
        >
            {children}
        </AuthContext.Provider>
    );
};

/**
 * Authentication context hook.
 */
export const useAuth = (): IAuthContext => useContext(AuthContext);

/**
 * Context holding the authentication state.
 */
const AuthContext = createContext<IAuthContext>({
    ...defaultAuthState,
    logout: () => undefined,
});

export default AuthContext;
