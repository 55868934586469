import { call, put, takeEvery } from 'redux-saga/effects';
import { INormalizedTestStep, normalizeTestStep } from '../normalizers/test-suite';
import { IShallowTestStep, ITestStep, ITestStepAttachment } from '../types';
import { api } from '../utils';
import {
    add_teststep,
    delete_teststep,
    testsuite_loader,
    testsuite_scroll_to,
    update_teststep,
} from '../store/test-suite';
import { alert } from '../store/alerts';

export const POST_TESTSTEP = 'POST_TESTSTEP';
export const POST_TESTSTEPS = 'POST_TESTSTEPS';
export const PUT_TESTSTEP = 'PUT_TESTSTEP';
export const PUT_TESTSTEPS = 'PUT_TESTSTEPS';
export const DELETE_TESTSTEP = 'DELETE_TESTSTEP';
export const ORDER_TESTSTEP = 'ORDER_TESTSTEP';

export const POST_TESTSTEP_ATTACHMENT = 'POST_TESTSTEP_ATTACHMENT';
export const DELETE_TESTSTEP_ATTACHMENT = 'DELETE_TESTSTEP_ATTACHMENT';

interface PostTestStepAction {
    type: typeof POST_TESTSTEP;
    payload: IShallowTestStep;
}

interface PostTestStepsAction {
    type: typeof POST_TESTSTEPS;
    payload: IShallowTestStep[];
}

interface PutTestStepAction {
    type: typeof PUT_TESTSTEP;
    payload: Partial<IShallowTestStep>;
}

interface PutTestStepsAction {
    type: typeof PUT_TESTSTEPS;
    payload: Partial<IShallowTestStep>[];
}

interface DeleteTestStepAction {
    type: typeof DELETE_TESTSTEP;
    payload: number[];
}

interface PostTestStepAttachment {
    type: typeof POST_TESTSTEP_ATTACHMENT;
    payload: {
        attachments: File[];
        testStep: IShallowTestStep;
    };
}

interface DeleteTestStepAttachment {
    type: typeof DELETE_TESTSTEP_ATTACHMENT;
    payload: {
        testStep: IShallowTestStep;
        attachment: ITestStepAttachment;
    };
}

export function* postTestStep(action: PostTestStepAction): Generator {
    try {
        const response = yield call(api, '/api/test_steps.json', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(action.payload),
        });

        const normalizedResponse = yield normalizeTestStep(response as ITestStep);

        yield put(
            add_teststep(
                (normalizedResponse as INormalizedTestStep<ITestStep>).entities.testSteps[
                    (normalizedResponse as INormalizedTestStep<ITestStep>).result
                ]
            )
        );

        yield put(testsuite_scroll_to((response as ITestStep).id));
    } catch (e) {
        yield put(alert((e as Error).message));
    }
}

export function* postTestSteps(action: PostTestStepsAction): Generator {
    try {
        const response = yield call(api, '/api/test_steps/bulk.json', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(action.payload),
        });

        const normalizedResponse = yield normalizeTestStep(response as ITestStep[]);

        yield put(
            add_teststep(
                (normalizedResponse as INormalizedTestStep<ITestStep[]>).result.map(
                    (testStepId: number) =>
                        (normalizedResponse as INormalizedTestStep<ITestStep[]>).entities.testSteps[testStepId]
                )
            )
        );
    } catch (e) {
        yield put(alert((e as Error).message));
    }
}

export function* putTestStep(action: PutTestStepAction): Generator {
    try {
        const response = yield call(api, `/api/test_steps/${action.payload.id}.json`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(action.payload),
        });

        const normalizedResponse = yield normalizeTestStep(response as ITestStep);

        yield put(
            update_teststep(
                (normalizedResponse as INormalizedTestStep<ITestStep>).entities.testSteps[
                    (normalizedResponse as INormalizedTestStep<ITestStep>).result
                ]
            )
        );
    } catch (e) {
        yield put(alert((e as Error).message));
    }
}

export function* putTestSteps(action: PutTestStepsAction): Generator {
    try {
        const response = yield call(api, `/api/test_steps/bulk.json`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(action.payload),
        });

        const normalizedResponse = yield normalizeTestStep(response as ITestStep[]);

        yield put(
            update_teststep(
                (normalizedResponse as INormalizedTestStep<ITestStep[]>).result.map(
                    (testStepId: number) =>
                        (normalizedResponse as INormalizedTestStep<ITestStep[]>).entities.testSteps[testStepId]
                )
            )
        );
    } catch (e) {
        yield put(alert((e as Error).message));
    }
}

export function* deleteTestStep(action: DeleteTestStepAction): Generator {
    try {
        yield call(api, `/api/test_steps/delete.json`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(action.payload.map((id) => ({ id }))),
        });

        yield put(delete_teststep(action.payload));
    } catch (e) {
        yield put(alert((e as Error).message));
    }
}

export function* postTestStepAttachment(action: PostTestStepAttachment): Generator {
    yield put(testsuite_loader(true));

    try {
        const formData = new FormData();
        action.payload.attachments.forEach((file, index) => formData.append(`file_${index}`, file));

        yield call(api, `/api/test_step_attachments/${action.payload.testStep.id}/upload.json`, {
            method: 'POST',
            body: formData,
        });

        yield put(
            update_teststep({
                ...action.payload.testStep,
                attachmentsLength: action.payload.testStep.attachmentsLength + action.payload.attachments.length,
            })
        );
    } catch (e) {
        yield put(alert((e as Error).message));
    }

    yield put(testsuite_loader(false));
}

export function* deleteTestStepAttachment(action: DeleteTestStepAttachment): Generator {
    try {
        yield call(api, `/api/test_step_attachments/${action.payload.attachment.id}`, {
            method: 'DELETE',
        });

        yield put(
            update_teststep({
                ...action.payload.testStep,
                attachmentsLength: action.payload.testStep.attachmentsLength - 1,
            })
        );
    } catch (e) {
        yield put(alert((e as Error).message));
    }
}

function* testStepSaga(): Generator {
    yield takeEvery(POST_TESTSTEP, postTestStep);
    yield takeEvery(POST_TESTSTEPS, postTestSteps);
    yield takeEvery(PUT_TESTSTEP, putTestStep);
    yield takeEvery(PUT_TESTSTEPS, putTestSteps);
    yield takeEvery(DELETE_TESTSTEP, deleteTestStep);
    yield takeEvery(POST_TESTSTEP_ATTACHMENT, postTestStepAttachment);
    yield takeEvery(DELETE_TESTSTEP_ATTACHMENT, deleteTestStepAttachment);
}

export default testStepSaga;
