import { diffWordsWithSpace } from 'diff';

/**
 * Removes Diacritics characters from a string.
 */
export const stripDiacritics = (str: string): string => {
    // 1. Normalize the string to Unicode.
    // 2. In Unicode, accented characters are split in 2 (i.e: `è` becomes `e` + ```), so we remove all non-letters/number characters.
    return str.normalize('NFKD').replace(/\p{Diacritic}/gu, '');
};

/**
 * Unescape a string containing HTML.
 * This function is guaranteed to not run any JS code. Any HTML tags will be ignored.
 * Only the text content will be returned.
 */
export const htmlDecode = (str: string): string => {
    const doc = new DOMParser().parseFromString(str, 'text/html');

    return doc.documentElement.textContent ?? '';
};

/**
 * Output the diff between the 2 given string as nodes, wrapping the changes into span
 * using either the "added" or "removed" CSS classes names.
 */
export const diffHtml = (oldStr: string, newStr: string): React.ReactNode => {
    const diff = diffWordsWithSpace(oldStr, newStr);

    return diff.map((part, index) => {
        if (part.added) {
            return (
                <span key={index} className="added">
                    {part.value}
                </span>
            );
        }

        return part.removed ? (
            <span key={index} className="removed">
                {part.value}
            </span>
        ) : (
            part.value
        );
    });
};

/**
 * Count the number of occurrences of a character in a string.
 * If the character is a string, the function will count the occurrences of each individually character.
 */
export const countCharOccurence = (str: string, char: string): number => {
    if (char.length > 1) {
        let count = 0;

        for (const c of char) {
            count += countCharOccurence(str, c);
        }

        return count;
    }

    return str.split(char).length - 1;
};
