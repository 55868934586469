import { createContext, PropsWithChildren, useContext, useEffect, useState } from 'react';
import SplashScreen from '../components/SplashScreen';

interface IAncestorContext {
    ancestor?: null | MessagePort | ServiceWorker | Window;
    isBugTrapp: null | boolean;
    isWeAreTesters: null | boolean;
    origin?: string;
    postMessage: (message: unknown) => void;
    hasAncestor: () => boolean;
}

type AncestorProviderProps = PropsWithChildren<unknown>;

const defaultContext: IAncestorContext = {
    isBugTrapp: null,
    isWeAreTesters: null,
    postMessage: () => undefined,
    hasAncestor: () => false,
};

const isMessagePort = (source: MessageEventSource): source is MessagePort =>
    typeof MessagePort !== 'undefined' && source instanceof MessagePort;
const isServiceWorker = (source: MessageEventSource): source is ServiceWorker =>
    typeof ServiceWorker !== 'undefined' && source instanceof ServiceWorker;

export const AncestorProvider = ({ children }: AncestorProviderProps): React.ReactElement => {
    const [context, setContext] = useState<IAncestorContext>(defaultContext);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [isInitialized, setIsInitialized] = useState<boolean>(false);
    const [tentative, setTentative] = useState<number>(10);

    /**
     * Check if the app has an ancestor. If the app is loaded into an iframe
     */
    const hasAncestor = () => window.self !== window.parent;

    /**
     * Send a message to the ancestor. (Wrap window.postMessage)
     */
    const postMessage = (message: unknown): void => {
        if (!hasAncestor()) {
            return;
        }

        if (!context.ancestor) {
            return;
        }

        // Don't send a message when we are either in Node or in a service worker
        // as the postMessage method works differently in there.
        if (isMessagePort(context.ancestor) || isServiceWorker(context.ancestor)) {
            return;
        }

        context.ancestor.postMessage(message, context.origin ?? '*');
    };

    useEffect(() => {
        // Skip if we don't have an ancestor (ie: not in an iFrame).
        if (!hasAncestor() || tentative === 0) {
            setIsLoading(false);
            setIsInitialized(true);
            return;
        }

        const handleMessage = (event: MessageEvent) => {
            // Ignore messages sent by itself.
            if (event.origin === window.location.origin) {
                return;
            }

            setIsLoading(false);

            setContext((previous) => ({
                ...previous,
                origin: event.origin,
                ancestor: event.source,
                isBugTrapp: process.env.BUGTRAPP_DOMAIN === event.origin,
                isWeAreTesters: process.env.WEARETESTERS_DOMAIN === event.origin,
            }));

            window.removeEventListener('message', handleMessage, false);
        };
        if (!isInitialized) {
            window.addEventListener('message', handleMessage, false);
            setIsInitialized(true);
        }
    }, [isInitialized, isLoading, tentative]);

    useEffect(() => {
        const whoAreYou = () => {
            window.parent.postMessage('WhoAreYou', '*');

            setTimeout(() => isLoading && setTentative((tentative) => tentative - 1), 250);
        };

        if (isLoading && tentative > 0) {
            whoAreYou();
        }
    }, [isLoading, tentative]);

    if (isLoading) {
        return <SplashScreen />;
    }

    return (
        <AncestorContext.Provider value={{ ...context, postMessage, hasAncestor }}>{children}</AncestorContext.Provider>
    );
};

export const useAncestor = (): IAncestorContext => useContext(AncestorContext);

const AncestorContext = createContext<IAncestorContext>(defaultContext);
