import { createSlice } from '@reduxjs/toolkit';
import { IUser } from '../types';
import type { IBaseState } from './actions';
import * as actions from './actions';

const initialState: IBaseState<IUser> = {
    byId: {},
    entities: [],
    loading: false,
    count: 0,
};

const users = createSlice({
    name: 'users',
    initialState,
    reducers: {
        ...actions,
    },
});

export const { update, set, set_loading } = users.actions;

export default users.reducer;
