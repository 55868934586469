import { ITestSuite, ILabel, ITestStep, IShallowTestStep, IShallowTestCase, IShallowTestSuite } from '../types';
import { normalize, schema } from 'normalizr';
import dayjs from 'dayjs';
import { IScope, IShallowScope, ITestCase } from '../types/test-suite';
import { NormalizedList } from '.';

export interface INormalizedTestSuite<T extends ITestSuite | ITestSuite[]> {
    entities: {
        labels: NormalizedList<ILabel>;
        scopes: NormalizedList<IShallowScope>;
        testCases: NormalizedList<IShallowTestCase>;
        testSteps: NormalizedList<IShallowTestStep>;
        testSuites: NormalizedList<IShallowTestSuite>;
    };
    result: T extends ITestSuite ? number : number[];
}

export interface INormalizedTestStep<T extends ITestStep | ITestStep[]> {
    entities: {
        labels: NormalizedList<ILabel>;
        testSteps: NormalizedList<IShallowTestStep>;
    };
    result: T extends ITestStep ? number : number[];
}

export interface INormalizedTestCase<T extends ITestCase | ITestCase[]> {
    entities: {
        labels: NormalizedList<ILabel>;
        testSteps: NormalizedList<IShallowTestStep>;
        testCases: NormalizedList<IShallowTestCase>;
    };
    result: T extends ITestCase ? number : number[];
}

export interface INormalizedScope<T extends IScope | IScope[]> {
    entities: {
        labels: NormalizedList<ILabel>;
        scopes: NormalizedList<IShallowScope>;
    };
    result: T extends IScope ? number : number[];
}

export const normalizeTestSuite = <T extends ITestSuite | ITestSuite[]>(testSuite: T): INormalizedTestSuite<T> => {
    const label = new schema.Entity('labels');
    const scope = new schema.Entity('scopes', {
        labels: [label],
    });
    const testStep = new schema.Entity(
        'testSteps',
        {
            labels: [label],
        },
        {
            processStrategy: (entity: ITestStep) => ({
                ...entity,
                updatedAt: dayjs(entity.updatedAt).toDate(),
                createdAt: dayjs(entity.createdAt).toDate(),
                labels: entity.labels ?? [],
            }),
        }
    );
    const testCase = new schema.Entity('testCases', {
        testSteps: [testStep],
    });

    const testSuiteSchema = new schema.Entity(
        'testSuites',
        {
            labels: [label],
            scopes: [scope],
            testCases: [testCase],
        },
        {
            processStrategy: (entity: ITestSuite) => ({
                ...entity,
                updatedAt: dayjs(entity.updatedAt).toDate(),
                createdAt: dayjs(entity.createdAt).toDate(),
                testCases: entity.testCases ?? [],
                labels: entity.labels ?? [],
                scopes: entity.scopes ?? [],
            }),
        }
    );

    return normalize(testSuite, Array.isArray(testSuite) ? [testSuiteSchema] : testSuiteSchema);
};

export const normalizeTestStep = <T extends ITestStep | ITestStep[]>(testStep: T): INormalizedTestStep<T> => {
    const label = new schema.Entity('labels');

    const testStepSchema = new schema.Entity(
        'testSteps',
        {
            labels: [label],
        },
        {
            processStrategy: (entity: ITestStep) => ({
                ...entity,
                updatedAt: dayjs(entity.updatedAt).toDate(),
                createdAt: dayjs(entity.createdAt).toDate(),
                labels: entity.labels ?? [],
            }),
        }
    );

    return normalize(testStep, Array.isArray(testStep) ? [testStepSchema] : testStepSchema);
};

export const normalizeTestCase = <T extends ITestCase | ITestCase[]>(testCase: T): INormalizedTestCase<T> => {
    const label = new schema.Entity('labels');
    const testStep = new schema.Entity(
        'testSteps',
        {
            labels: [label],
        },
        {
            processStrategy: (entity: ITestStep) => ({
                ...entity,
                updatedAt: dayjs(entity.updatedAt).toDate(),
                createdAt: dayjs(entity.createdAt).toDate(),
                labels: entity.labels ?? [],
            }),
        }
    );

    const testCaseSchema = new schema.Entity('testCases', { testSteps: [testStep] });

    return normalize(testCase, Array.isArray(testCase) ? [testCaseSchema] : testCaseSchema);
};

export const normalizeScope = <T extends IScope | IScope[]>(scope: T): INormalizedScope<T> => {
    const label = new schema.Entity('labels');
    const scopeSchema = new schema.Entity('scopes', {
        labels: [label],
    });

    return normalize(scope, Array.isArray(scope) ? [scopeSchema] : scopeSchema);
};
