import './i18n';
import App from './app/App';
import { StrictMode, useEffect } from 'react';
import * as Sentry from '@sentry/react';
import { VERSION_PATH } from './constants';
import { Provider } from 'react-redux';
import store from './store';
import { newVersionUpdater } from './utils/versionUpdater';
import { createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from 'react-router-dom';
import { alert } from './store/alerts';
import { createRoot } from 'react-dom/client';
import { AncestorProvider } from './contexts/AncestorContext';
import { SettingsProvider } from './contexts/SettingsContext';

Sentry.init({
    dsn: process.env.SENTRY_DSN,
    environment: process.env.SENTRY_ENVIRONMENT,
    enabled: process.env.SENTRY_ENABLED === 'true',
    integrations: [
        new Sentry.BrowserTracing({
            routingInstrumentation: Sentry.reactRouterV6Instrumentation(
                useEffect,
                useLocation,
                useNavigationType,
                createRoutesFromChildren,
                matchRoutes
            ),
        }),
    ],
    ignoreErrors: [
        // Random plugins/extensions
        'top.GLOBALS',
        // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
        'originalCreateNotification',
        'canvas.contentDocument',
        'MyApp_RemoveAllHighlights',
        'http://tt.epicplay.com',
        "Can't find variable: ZiteReader",
        'jigsaw is not defined',
        'ComboSearch is not defined',
        'http://loading.retry.widdit.com/',
        'atomicFindClose',
        // Facebook borked
        'fb_xd_fragment',
        // ISP "optimizing" proxy - `Cache-Control: no-transform` seems to reduce this. (thanks @acdha)
        // See http://stackoverflow.com/questions/4113268/how-to-stop-javascript-injection-from-vodafone-proxy
        'bmi_SafeAddOnload',
        'EBCallBackMessageReceived',
        // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
        'conduitPage',
        // Generic error code from errors outside the security sandbox
        // You can delete this if using raven.js > 1.0, which ignores these automatically.
        'Script error.',
        // Internal errors:
        // Mercure subscritpion reconnection
        'The connection has been closed by the server and should be re-established in 5s',
    ],
    denyUrls: [
        // Facebook flakiness
        /graph\.facebook\.com/i,
        // Facebook blocked
        /connect\.facebook\.net\/en_US\/all\.js/i,
        // Woopra flakiness
        /eatdifferent\.com\.woopra-ns\.com/i,
        /static\.woopra\.com\/js\/woopra\.js/i,
        // Chrome extensions
        /extensions\//i,
        /^chrome:\/\//i,
        // Other plugins
        /127\.0\.0\.1:4001\/isrunning/i, // Cacaoweb
        /webappstoolbarba\.texthelp\.com\//i,
        /metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
    ],
});

newVersionUpdater({
    COMMIT: process.env.COMMIT,
    VERSION_PATH,
    callback: () =>
        store.dispatch(
            alert({
                message: 'A new version of the application is available, please reload the page',
                type: 'warning',
                duration: null,
                action: {
                    label: 'reload',
                    callback: () => window.location.reload(),
                },
            })
        ),
});

// If the root is not there, we have bigger problems ;)
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = createRoot(document.getElementById('root')!);

root.render(
    <StrictMode>
        <Sentry.ErrorBoundary>
            <Provider store={store}>
                <AncestorProvider>
                    <SettingsProvider>
                        <App />
                    </SettingsProvider>
                </AncestorProvider>
            </Provider>
        </Sentry.ErrorBoundary>
    </StrictMode>
);
