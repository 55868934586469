import { PayloadAction } from '@reduxjs/toolkit';
import { Entity } from '../types';

export interface IBaseState<T extends Entity> {
    loading: boolean;
    lastId?: number | string;
    byId: Record<T['id'], T>;
    entities: T['id'][];
    count: number;
}

export const create = <T extends Entity>(
    state: IBaseState<T>,
    action: PayloadAction<{
        byId: Record<T['id'], Partial<T>>;
        entities: T['id'][];
        lastId: T['id'];
    }>
) => {
    state.lastId = action.payload.lastId;
    state.count = state.count + 1;
    update(state, action);
};

/**
 * Update the entities, count and byId record of the state, without resetting the current state.
 */
export const update = <T extends Entity>(
    state: IBaseState<T>,
    action: PayloadAction<{
        byId: Record<T['id'], Partial<T>>;
        entities: T['id'][];
    }>
) => {
    // Handle partial updates
    const updatedEntities = action.payload.entities.reduce((prev, id) => {
        if (!state.entities.includes(id)) {
            return {
                ...prev,
                [id]: action.payload.byId[id],
            };
        }

        return {
            ...prev,
            [id]: {
                ...state.byId[id],
                ...action.payload.byId[id],
            },
        };
    }, action.payload.byId);

    state.byId = {
        ...state.byId,
        ...updatedEntities,
    };
    state.entities = [...state.entities, ...action.payload.entities.filter((id) => !state.entities.includes(id))];
};

/**
 * Initialize the state, overriding anything that was already stored in it.
 */
export const set = <T extends Entity>(
    state: IBaseState<T>,
    action: PayloadAction<Pick<IBaseState<T>, 'byId' | 'count' | 'entities'>>
) => {
    state.byId = action.payload.byId;
    state.entities = action.payload.entities;
    state.count = action.payload.count;
    state.lastId = 0; // reset
};

/**
 * Removes the data for the entities with the given Ids from the state.
 */
export const remove = <T extends Entity>(state: IBaseState<T>, action: PayloadAction<T['id'][]>) => {
    action.payload.forEach((id) => delete state.byId[id]);
    state.entities = state.entities.filter((id) => !action.payload.includes(id));
    state.count = state.count - action.payload.length;
};

/**
 * Set the loading state.
 */
export const set_loading = <T extends Entity>(state: IBaseState<T>, action: PayloadAction<boolean>) => {
    state.loading = action.payload;
};
