/**
 * Insert given items into the given array at the given index.
 */
export const pushAt = <T = unknown>(input: T[] = [], index = 0, ...items: typeof input): typeof input =>
    input.slice(0, index).concat(...items, input.slice(index));

/**
 * Move the input's value located at the given source index to the given destination index.
 */
export const moveAt = <T = unknown>(input: T[], source: number, destination: number): typeof input => {
    const result = Array.from(input);

    // If the source is not a valid index, throw.
    if (!result[source]) {
        throw new Error(`Invalid key provided. ${source} does not exists in the given input.`);
    }

    const [removed] = result.splice(source, 1);
    result.splice(destination, 0, removed);

    return result;
};

/**
 * Callback to sort alphabetically elements using the Array.prototype.sort function.
 *
 * !! It's only useful to sort multidimensional arrays, otherwise just use
 * Array.prototype.sort, without parameter.
 */
export const sortAlphabetically = (a: number | string, b: number | string): number => {
    if (a === b) {
        return 0;
    }

    return a > b ? 1 : -1;
};

/**
 * Alternative way to sort array without falling into accidental mutations of state
 * see https://redux-toolkit.js.org/usage/immer-reducers#benefits-of-immer
 */
export const sort = <T = unknown>(arr: T[], callback: (a: T, b: T) => number): T[] => {
    return [...arr].sort(callback);
};
