import { normalize, schema } from 'normalizr';
import { NormalizedEntities } from '.';
import { IOperatingSystem } from '../types';

export const normalizeOperatingSystem = <T extends IOperatingSystem | IOperatingSystem[]>(
    operatingSystems: T
): NormalizedEntities<IOperatingSystem> => {
    const OperatingSystemSchema = new schema.Entity('byId', undefined, {
        processStrategy: (entity: IOperatingSystem) => ({ ...entity }),
    });

    return normalize(Array.isArray(operatingSystems) ? operatingSystems : [operatingSystems], [OperatingSystemSchema]);
};
