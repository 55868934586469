import { normalize, schema } from 'normalizr';
import { NormalizedEntities } from '.';
import { IDevice, Shallow } from '../types';

export const normalizeDevice = <T extends (IDevice | Shallow<IDevice>)[] | IDevice | Shallow<IDevice>>(
    devices: T
): NormalizedEntities<IDevice> => {
    const deviceSchema = new schema.Entity('byId', undefined, {
        processStrategy: (entity: IDevice) => entity,
    });

    return normalize(Array.isArray(devices) ? devices : [devices], [deviceSchema]);
};
