import dayjs from 'dayjs';
import { normalize, schema } from 'normalizr';
import { NormalizedEntities } from '.';
import { IDeviceList } from '../types';

export const normalizeDeviceList = <T extends IDeviceList | IDeviceList[]>(
    deviceLists: T
): NormalizedEntities<IDeviceList> => {
    const deviceListSchema = new schema.Entity('byId', undefined, {
        processStrategy: (entity: IDeviceList) => ({ ...entity, createdAt: dayjs(entity.createdAt).toDate() }),
    });

    return normalize(Array.isArray(deviceLists) ? deviceLists : [deviceLists], [deviceListSchema]);
};
