import { LinearProgress } from '@mui/material';
import { ReactElement } from 'react';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
    root: {
        minHeight: '100vh',
        width: '100%',
        backgroundColor: theme.palette.background.dark,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        '& > div': {
            padding: theme.spacing(3),
            width: '100%',
            maxWidth: 400,
        },
    },
}));

const SplashScreen = (): ReactElement => {
    const { classes } = useStyles();

    return (
        <div className={classes.root}>
            <div>
                <LinearProgress color="secondary" />
            </div>
        </div>
    );
};

export default SplashScreen;
