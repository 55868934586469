import { createSlice } from '@reduxjs/toolkit';
import { IProject } from '../types';
import * as actions from './actions';
import type { IBaseState } from './actions';

const initialState: IBaseState<IProject> = {
    byId: {},
    entities: [],
    loading: false,
    count: 0,
};

const projects = createSlice({
    name: 'projects',
    initialState,
    reducers: {
        ...actions,
    },
});

export const { update, set_loading } = projects.actions;

export default projects.reducer;
