import { ILabel, IShallowScope, IShallowTestCase, IShallowTestStep } from '../types';
import { getOwnKeys, moveAt, pushAt } from '../utils';
import dayjs from 'dayjs';
import { IState } from './test-suite';

/**
 * Push the given test step into the state.
 */
export const insertTestStep = (state: IState, testStep: IShallowTestStep): IState => {
    const newState = propagateTestStepUpdate(state, undefined, testStep);

    return reorderTestSteps(
        {
            ...newState,
            testSteps: {
                ...newState.testSteps,
                [testStep.id]: testStep,
            },
            testCases: {
                ...newState.testCases,
                [testStep.testCase]: {
                    ...newState.testCases[testStep.testCase],
                    testSteps: pushAt(newState.testCases[testStep.testCase].testSteps, testStep.order - 1, testStep.id),
                    testStepsLength: newState.testCases[testStep.testCase].testStepsLength + 1,
                },
            },
            testSuite: {
                ...newState.testSuite,
                updatedAt: dayjs().toDate(),
            },
        },
        testStep.testCase
    );
};

/**
 * Update state datas about the given test step.
 */
export const updateTestStep = (state: IState, testStep: IShallowTestStep): IState => {
    const prevTestStep = state.testSteps[testStep.id];
    const updatedTestStep = {
        ...prevTestStep,
        ...testStep,
    };
    let updatedState = state;

    // Update test case.
    if (prevTestStep.order !== updatedTestStep.order || prevTestStep.testCase !== updatedTestStep.testCase) {
        if (prevTestStep.testCase !== updatedTestStep.testCase) {
            // The test step moved to another test case, so both test case needs to be updated.
            updatedState = updateTestCase(updatedState, {
                ...updatedState.testCases[prevTestStep.testCase],
                testSteps: updatedState.testCases[prevTestStep.testCase].testSteps.filter(
                    (id) => id !== updatedTestStep.id
                ),
            });

            updatedState = reorderTestSteps(updatedState, prevTestStep.testCase);

            updatedState = updateTestCase(updatedState, {
                ...updatedState.testCases[updatedTestStep.testCase],
                testSteps: pushAt(
                    updatedState.testCases[updatedTestStep.testCase].testSteps,
                    updatedTestStep.order - 1,
                    updatedTestStep.id
                ),
            });
        } else {
            // Same test case, reorder just this one.
            updatedState = updateTestCase(updatedState, {
                ...updatedState.testCases[updatedTestStep.testCase],
                testSteps: moveAt(
                    updatedState.testCases[updatedTestStep.testCase].testSteps,
                    prevTestStep.order - 1,
                    updatedTestStep.order - 1
                ),
            });
        }

        // In either case the current test case's steps should be reordered
        updatedState = reorderTestSteps(updatedState, updatedTestStep.testCase);
    }

    updatedState = propagateTestStepUpdate(updatedState, prevTestStep, updatedTestStep);

    // Update test step.
    updatedState = {
        ...updatedState,
        testSteps: {
            ...updatedState.testSteps,
            [updatedTestStep.id]: {
                ...updatedState.testSteps[updatedTestStep.id],
                ...updatedTestStep,
            },
        },
        testSuite: {
            ...updatedState.testSuite,
            updatedAt: dayjs().toDate(),
        },
    };

    return updatedState;
};

/**
 * Update scopes and labels based on the change on the given test step.
 * If the test step is deleted, pass only prevTestStep parameter.
 * If the test step is created, pass only newTestStep parameter.
 *
 * @param state the state to update
 * @param prevTestStep the previous state of the test step
 * @param newTestStep the new state of the test step
 *
 * @return the updated state
 */
export const propagateTestStepUpdate = (
    state: IState,
    prevTestStep?: IShallowTestStep,
    newTestStep?: IShallowTestStep
): IState => {
    const addedLabel = newTestStep?.labels.filter((id) => !prevTestStep?.labels.includes(id));
    const removedLabel = prevTestStep?.labels.filter((id) => !newTestStep?.labels.includes(id));

    if (addedLabel && addedLabel.length > 0) {
        const scopesToUpdate = state.testSuite.scopes.filter(
            (scopeId) =>
                state.scopes[scopeId].labels.every((labelId) => newTestStep?.labels.includes(labelId)) &&
                addedLabel.some((labelId) => state.scopes[scopeId].labels.includes(labelId))
        );

        scopesToUpdate.forEach((id) => {
            state = updateScope(state, {
                ...state.scopes[id],
                testStepsLength: state.scopes[id].testStepsLength + 1,
            });
        });

        addedLabel.forEach((id) => {
            state = updateLabel(state, {
                ...state.labels[id],
                testStepsLength: state.labels[id].testStepsLength + 1,
            });
        });
    }

    if (removedLabel && removedLabel.length > 0) {
        const scopesToUpdate = state.testSuite.scopes.filter(
            (scopeId) =>
                state.scopes[scopeId].labels.every((labelId) => prevTestStep?.labels.includes(labelId)) &&
                removedLabel.some((labelId) => state.scopes[scopeId].labels.includes(labelId))
        );

        scopesToUpdate.forEach((id) => {
            state = updateScope(state, {
                ...state.scopes[id],
                testStepsLength: state.scopes[id].testStepsLength - 1,
            });
        });

        removedLabel.forEach((id) => {
            state = updateLabel(state, {
                ...state.labels[id],
                testStepsLength: state.labels[id].testStepsLength - 1,
            });
        });
    }

    return state;
};
/**
 * Removes the test steps with given id from the state.
 */
export const deleteTestStep = (state: IState, id: number): IState => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { [id]: removed, ...testSteps } = state.testSteps;

    const newState = propagateTestStepUpdate(state, removed);

    const testCases = {
        ...newState.testCases,
        [newState.testSteps[id].testCase]: {
            ...newState.testCases[newState.testSteps[id].testCase],
            testSteps: newState.testCases[newState.testSteps[id].testCase].testSteps.filter(
                (testStepId) => testStepId !== id
            ),
            testStepsLength: newState.testCases[newState.testSteps[id].testCase].testStepsLength - 1,
        },
    };

    return reorderTestSteps(
        {
            ...newState,
            testSteps,
            testCases,
            testSuite: {
                ...newState.testSuite,
                updatedAt: dayjs().toDate(),
            },
            ui: {
                ...newState.ui,
                selection: [],
            },
        },
        newState.testSteps[id].testCase
    );
};

/**
 * Reorder test steps under the given test case.
 */
const reorderTestSteps = (state: IState, testCaseId: number): IState => {
    const testCase = state.testCases[testCaseId];

    return testCase.testSteps.reduce((state, testStepId, index) => {
        return {
            ...state,
            testSteps: {
                ...state.testSteps,
                [testStepId]: {
                    ...state.testSteps[testStepId],
                    order: index + 1,
                },
            },
            testCases: {
                ...state.testCases,
                [testCaseId]: {
                    ...state.testCases[testCaseId],
                    testStepsLength: state.testCases[testCaseId].testSteps.length,
                },
            },
        };
    }, state);
};

/**
 * Removes the test case with the given id from the state.
 */
export const deleteTestCase = (state: IState, id: number): IState => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { [id]: removed, ...testCases } = state.testCases;

    // Update scopes & labels
    const newState = removed.testSteps.reduce(
        (state, id) => propagateTestStepUpdate(state, state.testSteps[id]),
        state
    );

    return reorderTestCases({
        ...newState,
        testCases,
        testSuite: {
            ...newState.testSuite,
            testCases: newState.testSuite.testCases.filter((testCase) => testCase !== id),
            updatedAt: dayjs().toDate(),
        },
    });
};

/**
 * Push the given test case into the state.
 */
export const insertTestCase = (
    state: IState,
    testCase: IShallowTestCase,
    testSteps?: Record<number, IShallowTestStep>
): IState => {
    return reorderTestCases({
        ...state,
        testCases: {
            ...state.testCases,
            [testCase.id]: testCase,
        },
        testSuite: {
            ...state.testSuite,
            testCases: pushAt(state.testSuite.testCases, testCase.order - 1, testCase.id),
            updatedAt: dayjs().toDate(),
        },
        testSteps: {
            ...state.testSteps,
            ...testSteps,
        },
    });
};

/**
 * Update state data about the given test case.
 */
export const updateTestCase = (state: IState, testCase: IShallowTestCase): IState => {
    const updatedState = {
        ...state,
        testCases: {
            ...state.testCases,
            [testCase.id]: testCase,
        },
        testSuite: {
            ...state.testSuite,
            testCases: moveAt(state.testSuite.testCases, state.testCases[testCase.id].order - 1, testCase.order - 1),
            updatedAt: dayjs().toDate(),
        },
    };

    return reorderTestCases(updatedState);
};

/**
 * Reorder test cases of the state's test suite.
 */
export const reorderTestCases = (state: IState): IState => {
    return state.testSuite.testCases.reduce((state, testCaseId, index) => {
        return {
            ...state,
            testCases: {
                ...state.testCases,
                [testCaseId]: {
                    ...state.testCases[testCaseId],
                    order: index + 1,
                },
            },
        };
    }, state);
};

/**
 * Removes the scope with the given id from the state.
 */
export const deleteScope = (state: IState, id: number): IState => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { [id]: removed, ...scopes } = state.scopes;

    return {
        ...state,
        scopes,
        testSuite: {
            ...state.testSuite,
            scopes: state.testSuite.scopes.filter((scope) => scope !== id),
            updatedAt: dayjs().toDate(),
        },
    };
};

/**
 * Push the given scope into the state.
 */
export const insertScope = (state: IState, scope: IShallowScope): IState => {
    return {
        ...state,
        scopes: {
            ...state.scopes,
            [scope.id]: scope,
        },
        testSuite: {
            ...state.testSuite,
            scopes: [...state.testSuite.scopes, scope.id],
            updatedAt: dayjs().toDate(),
        },
    };
};

/**
 * Update state data about the given scope.
 */
export const updateScope = (state: IState, scope: IShallowScope): IState => {
    return {
        ...state,
        scopes: {
            ...state.scopes,
            [scope.id]: scope,
        },
        testSuite: {
            ...state.testSuite,
            updatedAt: dayjs().toDate(),
        },
    };
};

/**
 * Removes the label with the given id from the state.
 */
export const deleteLabel = (state: IState, id: number): IState => {
    const { [id]: removed, ...labels } = state.labels;

    const testSteps = getOwnKeys(state.testSteps).reduce(
        (testSteps, id) => ({
            ...testSteps,
            [id]: {
                ...testSteps[id],
                labels: testSteps[id].labels.filter((label) => label !== removed.id),
            },
        }),
        state.testSteps
    );

    const scopesToUpdate = state.testSuite.scopes.filter((scopeId) =>
        state.scopes[scopeId].labels.includes(removed.id)
    );

    const scopes = scopesToUpdate.reduce((scopes, id) => {
        const scope = scopes[id];
        const scopeLabels = scope.labels.filter((label) => label !== removed.id);

        return {
            ...scopes,
            [id]: {
                ...scope,
                labels: scopeLabels,
                testStepsLength: getOwnKeys(testSteps).reduce((count, testStepId) => {
                    if (
                        scopeLabels.length > 0 &&
                        scopeLabels.every((labelId) => testSteps[testStepId].labels.includes(labelId))
                    ) {
                        return count + 1;
                    }

                    return count;
                }, 0),
            },
        };
    }, state.scopes);

    return {
        ...state,
        labels,
        scopes,
        testSteps,
        testSuite: {
            ...state.testSuite,
            labels: state.testSuite.labels.filter((label) => label !== id),
            updatedAt: dayjs().toDate(),
        },
    };
};

/**
 * Update state data about the given label.
 */
export const updateLabel = (state: IState, label: ILabel): IState => {
    return {
        ...state,
        labels: {
            ...state.labels,
            [label.id]: label,
        },
        testSuite: {
            ...state.testSuite,
            updatedAt: dayjs().toDate(),
        },
    };
};
