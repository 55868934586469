export const ENABLE_REDUX_DEV_TOOLS = !process.env.isEnvProduction;

// Specific routes.
export const HOME_URI = '/login';
export const OAUTH_CALLBACK_URI = '/oauth2/callback';

// Local storage keys.
export const OAUTH_TOKEN_STORAGE_KEY = '_token';
export const PKCE_STORAGE_KEY = '_pkce';
export const REFERRER_URI_STORAGE_KEY = '_referrer';
export const THEME_SETTINGS_STORAGE_KEY = '_settings';

// Sentry project's dsn.
export const SENTRY_ENABLED = process.env.SENTRY_ENABLED === 'true';

// BugTrapp Documentation URI.
export const BT_DOC_DOMAIN = 'https://doc.bugtrapp.com';
export const TESTSUITE_DOC_URI = '/fr/int_test-suites';

export const COMMIT = process.env.COMMIT;
export const VERSION_PATH = '/version.json';
