import { stringify } from 'querystring';
import { call, cancelled, put, takeEvery, takeLatest } from 'redux-saga/effects';
import { NormalizedEntities, normalizeConfiguration } from '../normalizers';
import { alert } from '../store/alerts';
import { set, set_loading, update } from '../store/configuration';
import { IConfiguration, IJsonLdCollection, IdentifiedPartial, JsonLdObject, Shallow, Unidentified } from '../types';
import { api } from '../utils';

export const PUT_CONFIGURATION = 'saga/configurations/put';
export const PUT_CONFIGURATIONS = 'saga/configurations/putCollection';
export const DELETE_CONFIGURATION = 'saga/configurations/delete';
export const GET_CONFIGURATION_LIST = 'saga/configurations/getList';
export const POST_CONFIGURATIONS = 'saga/configurations/post';
export const GET_BROWSER_VERSIONS = 'saga/browserVersions/get';
export const GET_DEVICES = 'saga/devices/get';

interface GetConfigurations {
    type: typeof GET_CONFIGURATION_LIST;
    payload: {
        campaign: number;
        page?: number;
        pageSize?: number;
    };
}

interface PutConfiguration {
    type: typeof PUT_CONFIGURATION;
    payload: IdentifiedPartial<Shallow<IConfiguration>>;
}

interface PutConfigurations {
    type: typeof PUT_CONFIGURATIONS;
    payload: IdentifiedPartial<Shallow<IConfiguration>>[];
}

interface DeleteConfiguration {
    type: typeof DELETE_CONFIGURATION;
    payload: {
        id: number;
        searchParams: {
            campaign: number;
            page?: number;
            pageSize?: number;
        };
    };
}

interface PostConfiguration {
    type: typeof POST_CONFIGURATIONS;
    payload: {
        configurations: Unidentified<Shallow<IConfiguration>>[];
        searchParams: {
            campaign: number;
            pagesize?: number;
            page?: number;
        };
    };
}

export function* postConfiguration(action: PostConfiguration): Generator {
    try {
        yield call(api, '/api/configurations.json', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(action.payload.configurations),
        });

        yield call(
            { fn: getConfigurationList, context: undefined },
            {
                type: GET_CONFIGURATION_LIST,
                payload: {
                    ...action.payload.searchParams,
                },
            }
        );
        yield put(alert({ message: 'configuration.created', type: 'success' }));
    } catch (e) {
        yield put(alert((e as Error).message));
    }
}

export function* deleteConfiguration(action: DeleteConfiguration): Generator {
    try {
        yield call(api, `/api/configurations/${action.payload.id}`, {
            method: 'DELETE',
        });

        yield call(
            { fn: getConfigurationList, context: undefined },
            {
                type: GET_CONFIGURATION_LIST,
                payload: {
                    ...action.payload.searchParams,
                },
            }
        );
    } catch (e) {
        yield put(alert((e as Error).message));
    }
}

export function* getConfigurationList(action: GetConfigurations): Generator {
    const abortController = new AbortController();

    try {
        yield put(set_loading(true));

        const queryParams = yield call(stringify, action.payload);

        const response = (yield call(api, `/api/configurations?${queryParams}`, {
            signal: abortController.signal,
        })) as IJsonLdCollection<IConfiguration>;

        const normalizedConfigurations = (yield call(
            normalizeConfiguration,
            response['hydra:member']
        )) as NormalizedEntities<IConfiguration>;

        yield put(
            set({
                byId: normalizedConfigurations.entities.byId,
                entities: normalizedConfigurations.result,
                count: response['hydra:totalItems'],
            })
        );
    } catch (e) {
        yield put(alert((e as Error).message));
    } finally {
        if (yield cancelled()) {
            yield abortController.abort();
        }

        yield put(set_loading(false));
    }
}

export function* putConfiguration(action: PutConfiguration): Generator {
    try {
        const response = (yield call(api, `/api/configurations/${action.payload.id}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(action.payload),
        })) as JsonLdObject<IConfiguration>;

        const normalizedConfiguration = (yield call(
            normalizeConfiguration,
            response
        )) as NormalizedEntities<IConfiguration>;

        yield put(
            update({
                byId: normalizedConfiguration.entities.byId,
                entities: normalizedConfiguration.result,
            })
        );

        yield put(alert({ message: 'configuration.updated', type: 'success' }));
    } catch (e) {
        yield put(alert((e as Error).message));
    }
}

export function* putConfigurations(action: PutConfigurations): Generator {
    try {
        const response = (yield call(api, `/api/configurations.json`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(action.payload),
        })) as IConfiguration[];

        const normalizedConfiguration = (yield call(
            normalizeConfiguration,
            response
        )) as NormalizedEntities<IConfiguration>;

        yield put(
            update({
                byId: normalizedConfiguration.entities.byId,
                entities: normalizedConfiguration.result,
            })
        );

        yield put(alert({ message: 'configuration.updated', type: 'success' }));
    } catch (e) {
        yield put(alert((e as Error).message));
    }
}

function* configurationSaga(): Generator {
    yield takeEvery(DELETE_CONFIGURATION, deleteConfiguration);
    yield takeEvery(PUT_CONFIGURATION, putConfiguration);
    yield takeEvery(PUT_CONFIGURATIONS, putConfigurations);
    yield takeLatest(GET_CONFIGURATION_LIST, getConfigurationList);
    yield takeEvery(POST_CONFIGURATIONS, postConfiguration);
}

export default configurationSaga;
