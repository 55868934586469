import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { Activity, Entity } from '../types';
import type { IBaseState } from './actions';
import * as actions from './actions';

const initialState: IBaseState<Activity<Entity>> = {
    byId: {},
    entities: [],
    loading: false,
    count: 0,
};

const activities = createSlice({
    name: 'activities',
    initialState,
    reducers: {
        ...actions,
        update: (
            state: IBaseState<Activity<Entity>>,
            action: PayloadAction<{
                byId: Record<Activity<Entity>['id'], Activity<Entity>>;
                entities: Activity<Entity>['id'][];
                /** if true the new elements will be added at the beginning of the list. */
                unshift?: boolean;
            }>
        ) => {
            // Handle partial updates
            const updatedEntities = action.payload.entities.reduce((prev, id) => {
                if (!state.entities.includes(id)) {
                    return {
                        ...prev,
                        [id]: action.payload.byId[id],
                    };
                }

                return {
                    ...prev,
                    [id]: {
                        ...state.byId[id],
                        ...action.payload.byId[id],
                    },
                };
            }, {});

            state.byId = {
                ...state.byId,
                ...updatedEntities,
            };

            const newIds = action.payload.entities.filter((id) => !state.entities.includes(id));
            state.entities = action.payload.unshift ? [...newIds, ...state.entities] : [...state.entities, ...newIds];
        },
    },
});

export const { update, remove, set_loading, set } = activities.actions;

export default activities.reducer;
