import { normalize, schema } from 'normalizr';
import { NormalizedEntities } from '.';
import { IUser, Shallow } from '../types';

export const normalizeUser = <T extends (IUser | Shallow<IUser>)[] | IUser | Shallow<IUser>>(
    users: T
): NormalizedEntities<IUser> => {
    const userSchema = new schema.Entity('byId', undefined, {
        processStrategy: (entity: IUser) => entity,
    });

    return normalize(Array.isArray(users) ? users : [users], [userSchema]);
};
