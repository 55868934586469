import { call, cancelled, put, takeEvery, takeLatest } from 'redux-saga/effects';
import { normalizeTestSuite as normalize } from '../normalizers';
import { testsuite_loader, testsuite_update } from '../store/test-suite';
import { IShallowTestSuite, ITestSuite } from '../types';
import { api } from '../utils';
import { alert } from '../store/alerts';

export const GET_TESTSUITE = 'GET_TESTSUITE';
export const PUT_TESTSUITE = 'PUT_TESTSUITE';
export const IMPORT_TESTSUITE = 'IMPORT_TESTSUITE';
export const IS_GRANTED_TESTSUITE_EDIT = 'IS_GRANTED_TESTSUITE_EDIT';

interface GetTestSuiteAction {
    type: typeof GET_TESTSUITE;
    payload: number | string;
}

interface PutTestSuiteAction {
    type: typeof PUT_TESTSUITE;
    payload: Partial<ITestSuite>;
}

interface ImportTestSuiteAction {
    type: typeof IMPORT_TESTSUITE;
    payload: {
        testSuite: IShallowTestSuite;
        file: File;
    };
}

export function* getTestSuite(action: GetTestSuiteAction): Generator {
    const abortController = new AbortController();

    yield put(testsuite_loader(true));

    try {
        const response = yield call(api, `/api/test_suites/${action.payload}.json`, { signal: abortController.signal });
        yield put(testsuite_update(normalize(response as ITestSuite)));
    } catch (e) {
        yield put(alert((e as Error).message));
    } finally {
        if (yield cancelled()) {
            yield abortController.abort();
        }

        yield put(testsuite_loader(false));
    }
}

export function* putTestSuite(action: PutTestSuiteAction): Generator {
    try {
        const testSuite = yield call(api, `/api/test_suites/${action.payload.id}.json`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(action.payload),
        });
        yield put(testsuite_update(normalize(testSuite as ITestSuite)));
    } catch (e) {
        yield put(alert((e as Error).message));
    }
}

export function* importTestSuite(action: ImportTestSuiteAction): Generator {
    yield put(testsuite_loader(true));

    try {
        const formData = new FormData();
        formData.append('_file', action.payload.file);
        yield call(api, `/api/test_suites/${action.payload.testSuite.id}/import.json`, {
            method: 'POST',
            body: formData,
        });
    } catch (e) {
        yield put(alert((e as Error).message));
    }
    yield put(testsuite_loader(false));
}

function* testSuiteSaga(): Generator {
    yield takeLatest(GET_TESTSUITE, getTestSuite);
    yield takeEvery(PUT_TESTSUITE, putTestSuite);
    yield takeEvery(IMPORT_TESTSUITE, importTestSuite);
}

export default testSuiteSaga;
