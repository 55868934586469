import { stringify } from 'querystring';
import { call, cancelled, put, takeEvery, takeLatest } from 'redux-saga/effects';
import { NormalizedEntities } from '../normalizers';
import { alert } from '../store/alerts';
import { IBaseModel, IJsonLdCollection, JsonLdObject } from '../types';
import { api } from '../utils';
import { normalizeModel } from '../normalizers/model';
import { create, remove, set, set_loading, update } from '../store/models';

export const GET_MODEL = 'saga/model/get';
export const GET_MODELS = 'saga/models/get';
export const DELETE_MODEL = 'saga/models/delete';
export const PUT_MODEL = 'saga/models/put';
export const POST_MODEL = 'saga/models/post';

interface GetModel {
    type: typeof GET_MODEL;
    payload: IBaseModel['id'];
}

interface GetModels {
    type: typeof GET_MODELS;
    payload: {
        page?: number;
        pageSize?: number;
    };
}

interface PutModel {
    type: typeof PUT_MODEL;
    payload: IBaseModel;
}

interface PostModel {
    type: typeof POST_MODEL;
    payload: IBaseModel;
}

interface DeleteModel {
    type: typeof DELETE_MODEL;
    payload: IBaseModel['id'];
}

export function* getItem(action: GetModel): Generator {
    const abortController = new AbortController();

    yield put(set_loading(true));

    try {
        const item = (yield call(api, `/api/base_models/${action.payload}.json`, {
            signal: abortController.signal,
        })) as IBaseModel;

        yield put(update({ byId: { [item.id]: item }, entities: [item.id] }));
    } catch (e) {
        yield put(alert((e as Error).message));
    } finally {
        if (yield cancelled()) {
            yield abortController.abort();
        }

        yield put(set_loading(false));
    }
}

export function* getCollection(action: GetModels): Generator {
    const abortController = new AbortController();

    yield put(set_loading(true));

    try {
        const queryParams = yield call(stringify, action.payload);
        const response = (yield call(api, `/api/base_models?${queryParams}`, {
            signal: abortController.signal,
        })) as IJsonLdCollection<IBaseModel>;
        const models = (yield call(normalizeModel, response['hydra:member'])) as NormalizedEntities<IBaseModel>;

        yield put(
            set({
                byId: models.entities.byId,
                entities: models.result,
                count: response['hydra:totalItems'],
            })
        );
    } catch (e) {
        yield put(alert((e as Error).message));
    } finally {
        if (yield cancelled()) {
            yield abortController.abort();
        }

        yield put(set_loading(false));
    }
}

export function* deleteItem(action: DeleteModel): Generator {
    yield put(set_loading(true));

    try {
        yield call(api, `/api/base_models/${action.payload}.json`, {
            method: 'DELETE',
        });

        yield put(remove([action.payload]));
    } catch (e) {
        yield put(alert((e as Error).message));
    } finally {
        yield put(set_loading(false));
    }
}

export function* putItem(action: PutModel): Generator {
    yield put(set_loading(true));
    try {
        const response = (yield call(api, `/api/base_models/${action.payload.id}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(action.payload),
        })) as JsonLdObject<IBaseModel>;

        const normalizedModel = (yield call(normalizeModel, response)) as NormalizedEntities<IBaseModel>;

        yield put(
            update({
                byId: normalizedModel.entities.byId,
                entities: normalizedModel.result,
            })
        );

        yield put(alert({ message: 'model.updated', type: 'success' }));
    } catch (e) {
        yield put(alert((e as Error).message));
    } finally {
        yield put(set_loading(false));
    }
}

export function* postItem(action: PostModel): Generator {
    yield put(set_loading(true));
    try {
        const response = (yield call(api, `/api/base_models`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(action.payload),
        })) as JsonLdObject<IBaseModel>;

        yield put(
            create({
                byId: { [response.id]: response },
                entities: [response.id],
                lastId: response.id,
            })
        );

        yield put(alert({ message: 'model.created', type: 'success' }));
    } catch (e) {
        yield put(alert((e as Error).message));
    } finally {
        yield put(set_loading(false));
    }
}

function* modelSaga(): Generator {
    yield takeLatest(GET_MODEL, getItem);
    yield takeLatest(GET_MODELS, getCollection);
    yield takeEvery(DELETE_MODEL, deleteItem);
    yield takeEvery(PUT_MODEL, putItem);
    yield takeEvery(POST_MODEL, postItem);
}

export default modelSaga;
