import dayjs from 'dayjs';
import { normalize, schema } from 'normalizr';
import { NormalizedList } from '.';
import { IIssue } from '../types';

export interface INormalizedIssue<T extends IIssue | IIssue[]> {
    entities: {
        issues: NormalizedList<IIssue>;
    };
    result: T extends IIssue ? number : number[];
}

export const normalizeIssue = <T extends IIssue | IIssue[]>(issue: T): INormalizedIssue<T> => {
    const issueSchema = new schema.Entity('issues', undefined, {
        processStrategy: (entity: IIssue) => ({
            ...entity,
            createdAt: dayjs(entity.createdAt).toDate(),
            updatedAt: dayjs(entity.updatedAt).toDate(),
        }),
    });

    return normalize(issue, Array.isArray(issue) ? [issueSchema] : issueSchema);
};
