import { AlertProps } from '@mui/material';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface Alerts {
    message: string;
    type?: AlertProps['color'];
    duration?: null | number;
    action?: {
        label: string;
        callback: () => void; // meh... not a good pratice to pass a callback in the store, I think...
    };
}

export interface IState {
    alerts: Alerts[];
}

const initialState: IState = {
    alerts: [],
};

const defaultAlert: Partial<Alerts> = {
    type: 'error',
    duration: 2000,
};

export const alerts = createSlice({
    name: 'alert',
    initialState,
    reducers: {
        alert: (state: IState, action: PayloadAction<Alerts | string>) => {
            state.alerts = [
                ...state.alerts,

                typeof action.payload === 'string'
                    ? { ...defaultAlert, message: action.payload }
                    : { ...defaultAlert, ...action.payload },
            ];
        },
        alert_close: (state: IState, action: PayloadAction<Alerts>) => {
            state.alerts = state.alerts.filter((alert) => alert === action.payload);
        },
    },
});
// Action creators are generated for each case reducer function
export const { alert, alert_close } = alerts.actions;

export default alerts.reducer;
