export const denormalizeFormValue = <T extends number | string>(input?: null | T): null | undefined | T => {
    switch (input) {
        case 'null':
            return null;

        case 'undefined':
        case '':
            return undefined;

        default:
            return input;
    }
};
