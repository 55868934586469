import { call, cancelled, put, takeEvery } from 'redux-saga/effects';
import { NormalizedEntities, normalizeUser } from '../normalizers';
import { alert } from '../store/alerts';
import { set, set_loading } from '../store/users';
import { IJsonLdCollection, IUser } from '../types';
import { api } from '../utils';

export const GET_USERS = 'saga/users/get';

interface GetUsers {
    type: typeof GET_USERS;
    payload: {
        queryParams: string;
    };
}

export function* getCollection(action: GetUsers): Generator {
    const abortController = new AbortController();

    yield put(set_loading(true));

    try {
        const response = (yield call(api, `/api/users?${action.payload.queryParams}`, {
            signal: abortController.signal,
        })) as IJsonLdCollection<IUser>;
        const users = (yield call(normalizeUser, response['hydra:member'])) as NormalizedEntities<IUser>;

        yield put(
            set({
                byId: users.entities.byId,
                entities: users.result,
                count: response['hydra:totalItems'],
            })
        );
    } catch (e) {
        yield put(alert((e as Error).message));
    } finally {
        if (yield cancelled()) {
            yield abortController.abort();
        }

        yield put(set_loading(false));
    }
}

function* userSaga(): Generator {
    yield takeEvery(GET_USERS, getCollection);
}

export default userSaga;
