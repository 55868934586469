export const TESTSTEP_DENSITY = {
    SMALL: 100,
    MEDIUM: 150,
    LARGE: 300,
} as const;

export const THEMES = {
    BUGTRAPP: 'BUGTRAPP',
    WEARETESTERS: 'WEARETESTERS',
    LIGHT: 'LIGHT',
    ONE_DARK: 'ONE_DARK',
    UNICORN: 'UNICORN',
} as const;

export type TestStepDensity = (typeof TESTSTEP_DENSITY)[keyof typeof TESTSTEP_DENSITY];
export type Direction = 'ltr' | 'rtl';
export type Theme = (typeof THEMES)[keyof typeof THEMES];

export interface ISettings {
    direction: Direction;
    responsiveFontSizes: boolean;
    theme: Theme;
    testSuite: { displayDensity: TestStepDensity };
}

export const defaultSettings: ISettings = {
    direction: 'ltr',
    responsiveFontSizes: true,
    theme: THEMES.BUGTRAPP,
    testSuite: { displayDensity: TESTSTEP_DENSITY.MEDIUM },
};
