import { wrapCreateBrowserRouter } from '@sentry/react';
import { lazy } from 'react';
import { createBrowserRouter, RouteObject } from 'react-router-dom';
import { HOME_URI, OAUTH_CALLBACK_URI } from './constants';

const IssueLabelsList = lazy(() => import('./app/project/labels/list'));
const ErrorBoundary = lazy(() => import('./app/errors/ErrorBoundary'));
const Error = lazy(() => import('./app/errors/Error'));
const Unauthorized = lazy(() => import('./app/errors/Unauthorized'));
const NotFound = lazy(() => import('./app/errors/NotFound'));
const Forbidden = lazy(() => import('./app/errors/Forbidden'));
const InternalServerError = lazy(() => import('./app/errors/InternalServerError'));
const Logout = lazy(() => import('./app/auth/Logout'));
const OAuthCallback = lazy(() => import('./app/auth/OAuthCallback'));
const Authorize = lazy(() => import('./app/auth/Authorize'));
const AuthGuard = lazy(() => import('./components/AuthGuard'));
const ProjectList = lazy(() => import('./app/project/list'));
const IssueLabelsEdit = lazy(() => import('./app/project/labels/edit'));
const TestSuiteEdit = lazy(() => import('./app/test-suite/edit'));
const IssueList = lazy(() => import('./app/issue/list'));
const IssueShow = lazy(() => import('./app/issue/show'));
const IssueImport = lazy(() => import('./app/issue/import'));
const ConfigurationImport = lazy(() => import('./app/configuration/import'));
const ConfigurationList = lazy(() => import('./app/configuration/list'));
const ConfigurationAdd = lazy(() => import('./app/configuration/add'));
const DeviceListShow = lazy(() => import('./app/device-list/show'));
const DeviceListList = lazy(() => import('./app/device-list/list'));
const DeviceList = lazy(() => import('./app/device/list'));
const ModelList = lazy(() => import('./app/model/list'));
const EditModel = lazy(() => import('./app/model/edit'));
const EditDevice = lazy(() => import('./app/device/edit'));
const ManufacturerList = lazy(() => import('./app/manufacturer'));
const ScreenResolutionList = lazy(() => import('./app/screen_resolution'));
const DeviceAdd = lazy(() => import('./app/device-list/add'));
const ExportPage = lazy(() => import('./app/issue/export'));
const OsVersionList = lazy(() => import('./app/os-version'));
const OperatingSystemList = lazy(() => import('./app/operating-system'));
const ReferralList = lazy(() => import('./app/user/referral'));
const ReferralProgram = lazy(() => import('./app/referral-program'));
const BrowserVersionList = lazy(() => import('./app/browser-version'));

const routes: RouteObject[] = [
    {
        path: '/error',
        children: [
            {
                index: true,
                element: <Error />,
            },
            {
                path: '401',
                element: <Unauthorized />,
            },
            {
                path: '404',
                element: <NotFound />,
            },
            {
                path: '403',
                element: <Forbidden />,
            },
            {
                path: '500',
                element: <InternalServerError />,
            },
        ],
    },
    {
        path: HOME_URI,
        element: <Logout />,
    },
    {
        path: OAUTH_CALLBACK_URI,
        element: <OAuthCallback />,
    },
    {
        path: '/authorize',
        element: <Authorize />,
    },
    {
        path: '/',
        element: <AuthGuard />,
        errorElement: <ErrorBoundary />,
        children: [
            {
                path: 'projects',
                children: [
                    {
                        index: true,
                        element: <ProjectList />,
                    },
                    {
                        path: ':id/labels',
                        children: [
                            {
                                index: true,
                                element: <IssueLabelsList />,
                            },
                            {
                                path: ':labelId/edit',
                                element: <IssueLabelsEdit />,
                            },
                            {
                                path: 'create',
                                element: <IssueLabelsEdit />,
                            },
                        ],
                    },
                ],
            },
            {
                path: 'test-suite/:id/edit',
                element: <TestSuiteEdit />,
            },
            {
                path: 'campaign/:campaignId',
                children: [
                    {
                        path: 'issues',
                        children: [
                            {
                                index: true,
                                element: <IssueList />,
                            },
                            {
                                path: ':id',
                                element: <IssueShow />,
                            },
                            {
                                path: 'import',
                                element: <IssueImport />,
                            },
                            {
                                path: 'export',
                                element: <ExportPage />,
                            },
                        ],
                    },
                    {
                        path: 'configurations',
                        children: [
                            {
                                index: true,
                                element: <ConfigurationList />,
                            },
                            {
                                path: 'add',
                                element: <ConfigurationAdd />,
                            },
                            {
                                path: 'import',
                                element: <ConfigurationImport />,
                            },
                        ],
                    },
                ],
            },
            {
                path: 'device-lists',
                children: [
                    {
                        index: true,
                        element: <DeviceListList />,
                    },
                    {
                        path: ':id/show',
                        element: <DeviceListShow />,
                    },
                    {
                        path: ':id/add',
                        element: <DeviceAdd />,
                    },
                ],
            },
            {
                path: 'os/versions',
                children: [
                    {
                        index: true,
                        element: <OsVersionList />,
                    },
                ],
            },
            {
                path: 'models',
                children: [
                    {
                        index: true,
                        element: <ModelList />,
                    },
                    {
                        path: 'create',
                        element: <EditModel />,
                    },
                    {
                        path: ':id',
                        element: <EditModel />,
                    },
                    {
                        path: ':modelId/devices',
                        children: [
                            {
                                path: ':id',
                                element: <EditDevice />,
                            },
                            {
                                path: 'create',
                                element: <EditDevice />,
                            },
                        ],
                    },
                ],
            },
            {
                path: 'devices',
                children: [
                    {
                        index: true,
                        element: <DeviceList />,
                    },
                    {
                        path: 'brands',
                        element: <ManufacturerList />,
                    },
                    {
                        path: 'screen_resolutions',
                        element: <ScreenResolutionList />,
                    },
                ],
            },
            {
                path: 'os',
                children: [
                    {
                        index: true,
                        element: <OperatingSystemList />,
                    },
                ],
            },
            {
                path: 'users',
                children: [
                    {
                        path: 'referral',
                        element: <ReferralList />,
                    },
                ],
            },
            {
                path: 'referral_program',
                children: [
                    {
                        index: true,
                        element: <ReferralProgram />,
                    },
                ],
            },
            {
                path: 'browserversion',
                children: [
                    {
                        index: true,
                        element: <BrowserVersionList />,
                    },
                ],
            },
        ],
    },
];

const sentryCreateBrowserRouter = wrapCreateBrowserRouter(createBrowserRouter);
const router = sentryCreateBrowserRouter(routes);

export default router;
