export const getOwnKeys = <T extends Record<number | string | symbol, unknown>>(input: T): (keyof T)[] => {
    const keys = Object.keys(input) as (keyof T)[];

    return keys.filter((key) => Object.prototype.hasOwnProperty.call(input, key));
};

/**
 * Converts an object or array into a one dimensionnal array.
 */
export const flatten = (input: unknown): unknown[] => {
    let result: unknown[] = [];

    if (Array.isArray(input)) {
        result = result.concat(_flattenArray(input));
    } else if (isObject(input)) {
        result = result.concat(_flattenObject(input));
    } else if (input) {
        result = result.concat([input]);
    }

    return result;
};

export const isObject = (input: unknown): input is Record<number | string | symbol, unknown> =>
    typeof input === 'object';

const _flattenObject = (input: Record<string, unknown>) => {
    const keys = Object.keys(input);
    let result: unknown[] = [];

    for (const key in keys) {
        if (Object.prototype.hasOwnProperty.call(input, keys[key])) {
            result = result.concat([keys[key]]).concat(flatten(input[keys[key]]));
        }
    }

    return result;
};

const _flattenArray = (input: unknown[]) => {
    let result: unknown[] = [];

    for (const key in input) {
        result = result.concat(flatten(input[key]));
    }

    return result;
};

export const filterObjectKeys = <T extends Record<number | string | symbol, unknown>, K extends keyof T>(
    input: T,
    keyToRemove: K
): Omit<T, K> => {
    return Object.keys(input)
        .filter((key) => key !== keyToRemove)
        .reduce((result, key) => ({ ...result, [key]: input[key] }), {} as Omit<T, K>);
};
