import { stringify } from 'querystring';
import { call, cancelled, put, takeLatest, takeEvery } from 'redux-saga/effects';
import { normalizeOsVersion } from '../normalizers/os-version';
import { api } from '../utils';

import { IOperatingSystemVersion, IJsonLdCollection, IdentifiedPartial } from '../types';
import { NormalizedEntities } from '../normalizers';
import { set_loading, set, update } from '../store/os-versions';
import { alert } from '../store/alerts';

export const GET_OS_VERSION_COLLECTION = 'saga/osVersions/getOsVersionCollection';
export const GET_OS_VERSION = 'saga/osVersion/getOsVersion';
export const PUT_OS_VERSION = 'saga/osVersion/putOsVersion';
export const POST_OS_VERSION = 'saga/osVersion/postOsVersion';
export const DELETE_OS_VERSION = 'saga/osVersion/deleteOsVersion';

interface GetOsVersionsList {
    type: typeof GET_OS_VERSION_COLLECTION;
    payload: {
        page?: number;
        pageSize?: number;
    };
}

interface GetOsVersion {
    type: typeof GET_OS_VERSION;
    payload: number;
}

interface PutOsVersion {
    type: typeof PUT_OS_VERSION;
    payload: IdentifiedPartial<IOperatingSystemVersion>;
}

interface PostOsVersion {
    type: typeof POST_OS_VERSION;
    payload: {
        osVersion: IdentifiedPartial<IOperatingSystemVersion>;
        searchParams: {
            page?: number;
            pageSize?: number;
        };
    };
}

interface DeleteOsVersion {
    type: typeof DELETE_OS_VERSION;
    payload: {
        osVersionId: number;
        page?: number;
        pageSize?: number;
    };
}

export function* getOsVersionCollection(action: GetOsVersionsList): Generator {
    try {
        yield put(set_loading(true));

        const queryParams = yield call(stringify, action.payload);

        const response = yield call(api, `/api/operating_system_versions?${queryParams}`);
        const osVersions = yield call(
            normalizeOsVersion,
            (response as IJsonLdCollection<IOperatingSystemVersion>)['hydra:member']
        );

        yield put(
            set({
                byId: (osVersions as NormalizedEntities<IOperatingSystemVersion>).entities.byId,
                entities: (osVersions as NormalizedEntities<IOperatingSystemVersion>).result,
                count: (response as IJsonLdCollection<IOperatingSystemVersion>)['hydra:totalItems'],
            })
        );
    } catch (e) {
        yield put(alert((e as Error).message));
    } finally {
        yield put(set_loading(false));
    }
}

export function* getOsVersion(action: GetOsVersion): Generator {
    const abortController = new AbortController();

    try {
        yield put(set_loading(true));

        const response = yield call(api, `/api/operating_system_versions/${action.payload}.json`);

        yield put(
            update({
                byId: (response as NormalizedEntities<IOperatingSystemVersion>).entities.byId,
                entities: (response as NormalizedEntities<IOperatingSystemVersion>).result,
            })
        );
    } catch (e) {
        yield put(alert((e as Error).message));
    } finally {
        if (yield cancelled()) {
            yield abortController.abort();
        }

        yield put(set_loading(false));
    }
}

export function* putOsVersion(action: PutOsVersion): Generator {
    try {
        yield put(set_loading(true));

        const response = yield call(api, `/api/operating_system_versions/${action.payload.id}.json`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(action.payload),
        });
        const osVersion = yield call(normalizeOsVersion, response as IOperatingSystemVersion);

        yield put(
            update({
                byId: (osVersion as NormalizedEntities<IOperatingSystemVersion>).entities.byId,
                entities: (osVersion as NormalizedEntities<IOperatingSystemVersion>).result,
            })
        );

        yield put(alert({ message: 'commons.updated', type: 'success' }));
    } catch (e) {
        yield put(alert((e as Error).message));
    } finally {
        yield put(set_loading(false));
    }
}

export function* postOsVersion(action: PostOsVersion): Generator {
    try {
        yield call(api, '/api/operating_system_versions.json', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(action.payload),
        });

        yield call(
            { fn: getOsVersionCollection, context: undefined },
            {
                type: GET_OS_VERSION_COLLECTION,
                payload: {
                    ...action.payload.searchParams,
                },
            }
        );

        yield put(alert({ message: 'osversion.created', type: 'success' }));
    } catch (e) {
        yield put(alert((e as Error).message));
    }
}

export function* deleteOsVersion(action: DeleteOsVersion): Generator {
    try {
        yield call(api, `/api/operating_system_versions/${action.payload.osVersionId}`, {
            method: 'DELETE',
        });

        yield call(
            { fn: getOsVersionCollection, context: undefined },
            {
                type: GET_OS_VERSION_COLLECTION,
                payload: {
                    ...action.payload,
                    page: action.payload.page,
                    pageSize: action.payload.pageSize,
                },
            }
        );

        yield put(alert({ message: 'commons.updated', type: 'success' }));
    } catch (e) {
        yield put(alert((e as Error).message));
    }
}

function* osVersionSaga(): Generator {
    yield takeLatest(GET_OS_VERSION_COLLECTION, getOsVersionCollection);
    yield takeLatest(GET_OS_VERSION, getOsVersion);
    yield takeEvery(PUT_OS_VERSION, putOsVersion);
    yield takeEvery(POST_OS_VERSION, postOsVersion);
    yield takeEvery(DELETE_OS_VERSION, deleteOsVersion);
}

export default osVersionSaga;
