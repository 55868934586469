import { createContext, ReactElement, ReactNode, useContext, useEffect, useState } from 'react';
import { THEME_SETTINGS_STORAGE_KEY } from '../constants';
import useLocalStorage from '../hooks/useLocalStorage';
import { useAncestor } from './AncestorContext';
import { ISettings, THEMES, Theme, defaultSettings } from '../settings';
interface ISettingsContext {
    settings: ISettings;
    saveSettings: (update: ISettings) => void;
}

interface SettingsProviderProps {
    settings?: ISettings;
    children?: ReactNode;
}

export const SettingsProvider = ({ settings, children }: SettingsProviderProps): ReactElement => {
    const { isBugTrapp } = useAncestor();
    const [theme, setTheme] = useState<null | Theme>(null);
    const [currentSettings, setCurrentSettings] = useLocalStorage<ISettings>(THEME_SETTINGS_STORAGE_KEY, {
        ...defaultSettings,
        ...settings,
    });

    const handleSaveSettings = (update: Partial<ISettings>): void => {
        setCurrentSettings((previous) => ({ ...previous, ...update }));
    };

    useEffect(() => {
        document.dir = currentSettings.direction;
    }, [currentSettings]);

    useEffect(() => {
        if (isBugTrapp === null) {
            return;
        }

        if (!theme) {
            setTheme(isBugTrapp ? THEMES.BUGTRAPP : THEMES.WEARETESTERS);
        }
    }, [isBugTrapp, theme]);

    return (
        <SettingsContext.Provider
            value={{
                settings: { ...currentSettings, theme: theme ?? THEMES.BUGTRAPP },
                saveSettings: handleSaveSettings,
            }}
        >
            {children}
        </SettingsContext.Provider>
    );
};

export const useSettings = (): ISettingsContext => useContext(SettingsContext);

const SettingsContext = createContext<ISettingsContext>({
    settings: defaultSettings,
    saveSettings: () => {
        // empty
    },
});

export default SettingsContext;
