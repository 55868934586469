import { call, put, takeEvery } from 'redux-saga/effects';
import { ILabel } from '../types';
import { api } from '../utils';
import { add_label, delete_label, update_label } from '../store/test-suite';
import { alert } from '../store/alerts';

export const DELETE_LABEL = 'DELETE_LABEL';
export const POST_LABEL = 'POST_LABEL';
export const PUT_LABEL = 'PUT_LABEL';

interface DeleteLabelAction {
    type: typeof DELETE_LABEL;
    payload: ILabel;
}

interface PostLabelAction {
    type: typeof POST_LABEL;
    payload: ILabel;
}

interface PutLabelAction {
    type: typeof PUT_LABEL;
    payload: ILabel;
}

export function* deleteLabel(action: DeleteLabelAction): Generator {
    try {
        yield call(api, `/api/labels/${action.payload.id}`, {
            method: 'DELETE',
        });

        yield put(delete_label(action.payload.id));
    } catch (e) {
        yield put(alert((e as Error).message));
    }
}

export function* postLabel(action: PostLabelAction): Generator {
    try {
        const label = yield call(api, '/api/labels.json', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(action.payload),
        });

        yield put(add_label(label as ILabel));
    } catch (e) {
        yield put(alert((e as Error).message));
    }
}

export function* putLabel(action: PutLabelAction): Generator {
    try {
        const label = yield call(api, `/api/labels/${action.payload.id}.json`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(action.payload),
        });

        yield put(update_label(label as ILabel));
    } catch (e) {
        yield put(alert((e as Error).message));
    }
}

function* labelSaga(): Generator {
    yield takeEvery(DELETE_LABEL, deleteLabel);
    yield takeEvery(POST_LABEL, postLabel);
    yield takeEvery(PUT_LABEL, putLabel);
}

export default labelSaga;
