import { stringify } from 'querystring';
import { call, put, takeLatest, takeEvery } from 'redux-saga/effects';
import { normalizeOperatingSystem } from '../normalizers/operatingSystem';
import { api } from '../utils';

import { IOperatingSystem, IJsonLdCollection, IdentifiedPartial } from '../types';
import { NormalizedEntities } from '../normalizers';
import { set_loading, set, update } from '../store/operating-system';
import { alert } from '../store/alerts';

export const GET_OPERATING_SYSTEM_COLLECTION = 'saga/operating-system/getOperatingSystemCollection';
export const PUT_OPERATING_SYSTEM = 'saga/operating-system/putOperatingSystem';
export const POST_OPERATING_SYSTEM = 'saga/operating-system/postOperatingSystem';
export const DELETE_OPERATING_SYSTEM = 'saga/operating-system/deleteOperatingSystem';

interface GetOperatingSystemList {
    type: typeof GET_OPERATING_SYSTEM_COLLECTION;
    payload: {
        page?: number;
        pageSize?: number;
    };
}

interface PostOperatingSystem {
    type: typeof POST_OPERATING_SYSTEM;
    payload: {
        operatingSystem: IdentifiedPartial<IOperatingSystem>;
        searchParams: {
            page?: number;
            pageSize?: number;
        };
    };
}

interface PutOperatingSystem {
    type: typeof PUT_OPERATING_SYSTEM;
    payload: IdentifiedPartial<IOperatingSystem>;
}

interface DeleteOperatingSystem {
    type: typeof DELETE_OPERATING_SYSTEM;
    payload: {
        operatingSystemId: number;
        page?: number;
        pageSize?: number;
    };
}

export function* getOperatingSystemCollection(action: GetOperatingSystemList): Generator {
    try {
        yield put(set_loading(true));

        const queryParams = yield call(stringify, action.payload);

        const response = yield call(api, `/api/operating_systems?${queryParams}`);
        const operatingSystem = yield call(
            normalizeOperatingSystem,
            (response as IJsonLdCollection<IOperatingSystem>)['hydra:member']
        );

        yield put(
            set({
                byId: (operatingSystem as NormalizedEntities<IOperatingSystem>).entities.byId,
                entities: (operatingSystem as NormalizedEntities<IOperatingSystem>).result,
                count: (response as IJsonLdCollection<IOperatingSystem>)['hydra:totalItems'],
            })
        );
    } catch (e) {
        yield put(alert((e as Error).message));
    } finally {
        yield put(set_loading(false));
    }
}

export function* postOperatingSystem(action: PostOperatingSystem): Generator {
    try {
        yield call(api, '/api/operating_systems.json', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(action.payload),
        });

        yield call(
            { fn: getOperatingSystemCollection, context: undefined },
            {
                type: GET_OPERATING_SYSTEM_COLLECTION,
                payload: {
                    ...action.payload.searchParams,
                },
            }
        );

        yield put(alert({ message: 'configuration.created', type: 'success' }));
    } catch (e) {
        yield put(alert((e as Error).message));
    }
}

export function* putOperatingSystem(action: PutOperatingSystem): Generator {
    try {
        yield put(set_loading(true));

        const response = yield call(api, `/api/operating_systems/${action.payload.id}.json`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(action.payload),
        });
        const operatingSystem = yield call(normalizeOperatingSystem, response as IOperatingSystem);

        yield put(
            update({
                byId: (operatingSystem as NormalizedEntities<IOperatingSystem>).entities.byId,
                entities: (operatingSystem as NormalizedEntities<IOperatingSystem>).result,
            })
        );

        yield put(alert({ message: 'commons.updated', type: 'success' }));
    } catch (e) {
        yield put(alert((e as Error).message));
    } finally {
        yield put(set_loading(false));
    }
}

export function* deleteOperatingSystem(action: DeleteOperatingSystem): Generator {
    try {
        yield call(api, `/api/operating_systems/${action.payload.operatingSystemId}`, {
            method: 'DELETE',
        });

        yield call(
            { fn: getOperatingSystemCollection, context: undefined },
            {
                type: GET_OPERATING_SYSTEM_COLLECTION,
                payload: {
                    ...action.payload,
                    page: action.payload.page,
                    pageSize: action.payload.pageSize,
                },
            }
        );

        yield put(alert({ message: 'commons.updated', type: 'success' }));
    } catch (e) {
        yield put(alert((e as Error).message));
    }
}

function* operatingSytemSaga(): Generator {
    yield takeLatest(GET_OPERATING_SYSTEM_COLLECTION, getOperatingSystemCollection);
    yield takeEvery(POST_OPERATING_SYSTEM, postOperatingSystem);
    yield takeEvery(PUT_OPERATING_SYSTEM, putOperatingSystem);
    yield takeEvery(DELETE_OPERATING_SYSTEM, deleteOperatingSystem);
}

export default operatingSytemSaga;
