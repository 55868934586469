import { call, put, select, takeEvery } from 'redux-saga/effects';
import { IShallowTestCase, ITestCase } from '../types';
import { api } from '../utils';
import { RootState } from '../store';
import { INormalizedTestCase, normalizeTestCase } from '../normalizers/test-suite';
import { add_testcase, delete_testcase, testsuite_sidebar_scroll_to, update_testcase } from '../store/test-suite';
import { alert } from '../store/alerts';

export const ORDER_TESTCASE = 'ORDER_TESTCASE';
export const PUT_TESTCASE = 'PUT_TESTCASE';
export const POST_TESTCASE = 'POST_TESTCASE';
export const DELETE_TESTCASE = 'DELETE_TESTCASE';

interface OrderTestCaseAction {
    type: typeof ORDER_TESTCASE;
    payload: {
        source: number;
        destination: number;
    };
}

interface PutTestCaseAction {
    type: typeof PUT_TESTCASE;
    payload: IShallowTestCase;
}

interface PostTestCaseAction {
    type: typeof POST_TESTCASE;
    payload: ITestCase & { testSuite: number };
}

interface DeleteTestCaseAction {
    type: typeof DELETE_TESTCASE;
    payload: IShallowTestCase;
}

export function* orderTestCase(action: OrderTestCaseAction): Generator {
    try {
        const state = yield select((store: RootState) => store.testSuite);
        const testCaseId = (state as RootState['testSuite']).testSuite.testCases[action.payload.source];
        const testCase = (state as RootState['testSuite']).testCases[testCaseId];

        yield put(
            update_testcase({
                ...testCase,
                order: action.payload.destination + 1,
            })
        );

        yield call(api, `/api/test_cases/${testCase.id}.json`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ order: action.payload.destination + 1 }),
        });
    } catch (e) {
        yield put(alert((e as Error).message));
    }
}

export function* putTestCase(action: PutTestCaseAction): Generator {
    try {
        const response = yield call(api, `/api/test_cases/${action.payload.id}.json`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(action.payload),
        });

        const normalizedResponse = yield normalizeTestCase(response as ITestCase);

        yield put(
            update_testcase(
                (normalizedResponse as INormalizedTestCase<ITestCase>).entities.testCases[
                    (normalizedResponse as INormalizedTestCase<ITestCase>).result
                ]
            )
        );
    } catch (e) {
        yield put(alert((e as Error).message));
    }
}

export function* postTestCase(action: PostTestCaseAction): Generator {
    try {
        const response = yield call(api, `/api/test_cases.json`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(action.payload),
        });

        const normalizedResponse = yield normalizeTestCase(response as ITestCase);

        yield put(
            add_testcase({
                testCase: (normalizedResponse as INormalizedTestCase<ITestCase>).entities.testCases[
                    (normalizedResponse as INormalizedTestCase<ITestCase>).result
                ],
                testSteps: (normalizedResponse as INormalizedTestCase<ITestCase>).entities.testSteps,
            })
        );

        yield put(testsuite_sidebar_scroll_to((response as ITestCase).id));
    } catch (e) {
        yield put(alert((e as Error).message));
    }
}

export function* deleteTestCase(action: DeleteTestCaseAction): Generator {
    try {
        yield call(api, `/api/test_cases/${action.payload.id}`, {
            method: 'DELETE',
        });

        yield put(delete_testcase(action.payload.id));
    } catch (e) {
        yield put(alert((e as Error).message));
    }
}

function* testCaseSaga(): Generator {
    yield takeEvery(PUT_TESTCASE, putTestCase);
    yield takeEvery(POST_TESTCASE, postTestCase);
    yield takeEvery(DELETE_TESTCASE, deleteTestCase);
    yield takeEvery(ORDER_TESTCASE, orderTestCase);
}

export default testCaseSaga;
