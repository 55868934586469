/**
 * Inserts text into a textarea/input field.
 *
 * The `execCommand` is officially deprecated.  However, for `insertText`,
 * there is currently no alternative. We need to use it in order to trigger
 * the browser's undo tracking when we insert text.
 */
const _doInsertText = (text: string): boolean => {
    if (text === '') {
        return document.execCommand('delete');
    }

    return document.execCommand('insertText', false, text);
};

/**
 * @see https://github.com/facebook/react/issues/10135#issuecomment-314441175
 */
const _setElementValue = (element: HTMLElement, value: string): void => {
    const valueSetter = Object.getOwnPropertyDescriptor(element, 'value')?.set;
    const prototype = Object.getPrototypeOf(element);
    const prototypeValueSetter = Object.getOwnPropertyDescriptor(prototype, 'value')?.set;

    if (valueSetter && valueSetter !== prototypeValueSetter) {
        prototypeValueSetter?.call(element, value);
    } else {
        valueSetter?.call(element, value);
    }
};

/**
 * Inserts the given string into the textarea, at the cursor position.
 */
export const insertText = (textArea: HTMLTextAreaElement, str: string): void => {
    // The `execCommand` is officially deprecated.  However, for `insertText`,
    // there is currently no alternative. We need to use it in order to trigger
    // the browser's undo tracking when we insert text.
    // So we attempt to use it if possible. Otherwise, fall back to just replacing
    // the value as before. In this case, Undo will be broken with inserted text.
    if (!_doInsertText(str)) {
        const { value, selectionStart, selectionEnd } = textArea;
        const textBefore = value.substring(0, selectionStart);
        const textAfter = value.substring(selectionEnd);
        const newValue = textBefore + str + textAfter;

        _setElementValue(textArea, newValue);
    }

    textArea.dispatchEvent(new Event('input', { bubbles: true }));
    textArea.dispatchEvent(new Event('change', { bubbles: true }));
};
