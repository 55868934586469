import dayjs from 'dayjs';
import { normalize, schema } from 'normalizr';
import { NormalizedEntities } from '.';
import { IConfiguration, Shallow } from '../types';

export const normalizeConfiguration = <
    T extends (IConfiguration | Shallow<IConfiguration>)[] | IConfiguration | Shallow<IConfiguration>,
>(
    configurations: T
): NormalizedEntities<IConfiguration> => {
    const configurationSchema = new schema.Entity('byId', undefined, {
        processStrategy: (entity: IConfiguration) => ({ ...entity, createdAt: dayjs(entity.createdAt).toDate() }),
    });

    return normalize(Array.isArray(configurations) ? configurations : [configurations], [configurationSchema]);
};
