import { createSlice } from '@reduxjs/toolkit';
import { IDevice } from '../types';
import type { IBaseState } from './actions';
import * as actions from './actions';

const initialState: IBaseState<IDevice> = {
    byId: {},
    entities: [],
    loading: false,
    count: 0,
};

const devices = createSlice({
    name: 'devices',
    initialState,
    reducers: {
        ...actions,
    },
});

export const { create, update, remove, set_loading, set } = devices.actions;

export default devices.reducer;
