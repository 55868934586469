import createSagaMiddleware from 'redux-saga';
import reducers from './reducers';
import { createReduxEnhancer } from '@sentry/react';
import { configureStore } from '@reduxjs/toolkit';
import sagas from '../sagas';
import { TypedUseSelectorHook, useDispatch as useReduxDispatch, useSelector as useReduxSelector } from 'react-redux';

export type RootState = ReturnType<typeof store.getState>;
const sagaMiddleware = createSagaMiddleware();
const sentryReduxEnhancer = createReduxEnhancer();
const store = configureStore({
    reducer: reducers,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false,
        }).concat(sagaMiddleware),
    devTools: process.env.NODE_ENV !== 'production',
    enhancers: [sentryReduxEnhancer],
});

export type AppDispatch = typeof store.dispatch;

export const useSelector: TypedUseSelectorHook<RootState> = useReduxSelector;
export const useDispatch: () => AppDispatch = useReduxDispatch;

sagaMiddleware.run(sagas);

export default store;
