import { Mutex } from 'async-mutex';
import { OAUTH_CALLBACK_URI, OAUTH_TOKEN_STORAGE_KEY } from '../constants';
import { ApiError, IOAuthToken, isApiJsonError, isApiJsonLdError } from '../types';

const JSON_CONTENT_TYPES = ['application/json', 'application/problem+json', 'application/ld+json'];

/**
 * Custom error for the API's http errors. Add a code property holding the received  HTTPS status code.
 */
export class ApiGenericError extends Error {
    code: number;
    message: string;
    name: string;
    response?: ApiError;
    payload?: unknown;

    constructor(message: string, code?: number, response?: ApiError, payload?: unknown) {
        super(message);
        this.name = 'ApiGenericError';
        this.message = message;
        this.code = code ?? 0;
        this.response = response;
        this.payload = payload;
    }

    toString(): string {
        return this.message;
    }
}

const mutex = new Mutex();

export const api = async <T = undefined>(uri: string, options?: RequestInit): Promise<T> => {
    // Wait until any token refresh process is done.
    await mutex.waitForUnlock();

    const token = await _resolveToken();
    const url = `${process.env.API_BASE_URL}${uri}`;

    const response = await fetch(url, _setAuthorizationHeader(options, token));

    if (!response) {
        throw new Error('errors.network');
    }

    if (!response.ok) {
        throw await _createError(response, options);
    }

    const contentType = response.headers.get('content-type');
    if (contentType?.includes('json')) {
        return (await response.json()) as T;
    }

    if (contentType?.includes('text')) {
        return (await response.text()) as unknown as T;
    }

    // TODO - This is not ideal. Maybe there's a more clean way to handle unknown response type.
    return (await response.blob()) as unknown as T;
};

/**
 * Build the URI, the OAuth server should redirect to.
 */
export const getRedirectUrl = (): string => {
    const hostname = window.location.hostname;
    const protocol = window.location.protocol;
    const port = window.location.port;

    return `${protocol}//${hostname}${port ? `:${port}` : ''}${OAUTH_CALLBACK_URI}`;
};

/**
 * Calculate token's expiration timestamp (in seconds).
 */
export const getExpirationTimeStamp = (token: IOAuthToken): number => {
    return token.expires_in + Math.floor(Date.now() / 1000);
};

/**
 * Check if the token is still valid.
 */
export const isTokenValid = (token: IOAuthToken): boolean => {
    const now = Math.floor(Date.now() / 1000);
    return token.expires_in > now;
};

/**
 * Set Authorization header in the given fetch's options.
 */
const _setAuthorizationHeader = (options?: RequestInit, token?: IOAuthToken): undefined | RequestInit => {
    if (token) {
        return {
            ...options,
            headers: {
                ...options?.headers,
                Authorization: `${token.token_type} ${token.access_token}`,
            },
        };
    }

    return options;
};

/**
 * Get a valid token from the local storage or by fetching a new one if it's expired.
 */
const _resolveToken = async (): Promise<undefined | IOAuthToken> => {
    const token = _getStoredToken();

    if (!token) {
        return;
    }

    if (token && isTokenValid(token)) {
        return token;
    }

    // If the mutex is locked, wait until it's released and try again.
    if (mutex.isLocked()) {
        await mutex.waitForUnlock();
        return await _resolveToken();
    }

    // Refreshing the access token is required, take the lock.
    const release = await mutex.acquire();

    try {
        const newToken = await _refreshToken(token);
        _setStoredToken(newToken);

        return {
            ...newToken,
            expires_in: getExpirationTimeStamp(newToken),
        };
    } finally {
        release();
    }
};

/**
 * Request a new token using the passed one.
 */
const _refreshToken = async (token: IOAuthToken): Promise<IOAuthToken> => {
    const response = await fetch(`${process.env.API_BASE_URL}/token`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            grant_type: 'refresh_token',
            client_id: process.env.API_CLIENT_ID,
            refresh_token: token.refresh_token,
        }),
    });

    if (!response.ok) {
        // The current token is not refreshable. Remove it from the local storage.
        _removeStoredToken();
        throw _createError(response);
    }

    return (await response.json()) as IOAuthToken;
};

/**
 * Get a token from the local storage.
 */
const _getStoredToken = (): undefined | IOAuthToken => {
    const serializedToken = window.localStorage.getItem(OAUTH_TOKEN_STORAGE_KEY) ?? undefined;
    return serializedToken ? JSON.parse(serializedToken) : undefined;
};

/**
 * Sets a token into the local storage.
 */
const _setStoredToken = (token: IOAuthToken) => {
    window.localStorage.setItem(
        OAUTH_TOKEN_STORAGE_KEY,
        JSON.stringify({ ...token, expires_in: getExpirationTimeStamp(token) })
    );
};

/**
 * Removes the token from the local storage.
 */
const _removeStoredToken = () => {
    window.localStorage.removeItem(OAUTH_TOKEN_STORAGE_KEY);
};

/**
 * Generate HTTPError with a proper message based on the response from the API.
 */
const _createError = async (response: Response, options?: RequestInit): Promise<ApiGenericError> => {
    const contentTypes = response.headers.get('content-type')?.split(';');

    const requestBody = _getRequestContent(options);

    if (contentTypes?.some((contentType) => JSON_CONTENT_TYPES.includes(contentType))) {
        const error = (await response.json()) as object;

        if (isApiJsonError(error)) {
            return new ApiGenericError(error.detail, response.status, error, requestBody);
        }

        if (isApiJsonLdError(error)) {
            return new ApiGenericError(error['hydra:description'], response.status, error, requestBody);
        }
    }

    return new ApiGenericError(`http_status.${response.status}`, response.status, undefined, requestBody);
};

/**
 * Extract the body from the given RequestInit object.
 * If the content type can be identified, the body will be deserialized.
 */
const _getRequestContent = (options?: RequestInit): unknown => {
    if (!options) {
        return undefined;
    }

    const requestBody = options.body?.toString();
    if (!requestBody) {
        return undefined;
    }

    const headers = new Headers(options.headers);
    const contentType = headers.get('content-type') ?? '';

    if (JSON_CONTENT_TYPES.includes(contentType)) {
        return JSON.parse(requestBody);
    }

    return requestBody;
};
