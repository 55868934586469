import { captureException } from '@sentry/react';

interface VersionUpdaterConfig {
    COMMIT?: string;
    VERSION_PATH: string;
    callback: () => void;
}

const TEN_MINUTES = 10 * 60 * 1000;

const isDifferent = (a?: string, b?: string) => !!a && !!b && a !== b;

export const newVersionUpdater = ({ COMMIT, VERSION_PATH, callback }: VersionUpdaterConfig): void => {
    let versionIntervalId: null | number = null;

    const clearVersionInterval = () => {
        if (versionIntervalId) {
            window.clearInterval(versionIntervalId);
            versionIntervalId = null;
        }
    };

    const getVersion = async () => {
        const response = await fetch(VERSION_PATH, {
            cache: 'no-store',
        });
        return response.json();
    };

    const isNewVersionAvailable = async () => {
        try {
            const { commit } = await getVersion();

            return isDifferent(commit, COMMIT);
        } catch (e) {
            captureException(e);
        }
    };

    const checkForNewerVersion = async () => {
        if (await isNewVersionAvailable()) {
            clearVersionInterval();
            callback();
        }
    };

    versionIntervalId = window.setInterval(() => {
        checkForNewerVersion();
    }, TEN_MINUTES);
};
