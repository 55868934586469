import { call, cancelled, put, takeLatest } from 'redux-saga/effects';
import { alert } from '../store/alerts';
import { set_loading, update } from '../store/projects';
import { IProject } from '../types';
import { api } from '../utils';

export const GET_PROJECT = 'saga/project/get';

interface GetProjectAction {
    type: typeof GET_PROJECT;
    payload: number;
}

export function* getProject(action: GetProjectAction): Generator {
    const abortController = new AbortController();

    yield put(set_loading(true));

    try {
        const project = (yield call(api, `/api/projects/${action.payload}.json`, {
            signal: abortController.signal,
        })) as IProject;

        yield put(update({ byId: { [project.id]: project }, entities: [project.id] }));
    } catch (e) {
        yield put(alert((e as Error).message));
    } finally {
        if (yield cancelled()) {
            yield abortController.abort();
        }

        yield put(set_loading(false));
    }
}

function* projectSaga(): Generator {
    yield takeLatest(GET_PROJECT, getProject);
}

export default projectSaga;
