import { stringify } from 'querystring';
import { call, cancelled, put, takeEvery, takeLatest } from 'redux-saga/effects';
import { NormalizedEntities } from '../normalizers';
import { alert } from '../store/alerts';
import { IDevice, IJsonLdCollection, JsonLdObject } from '../types';
import { api } from '../utils';
import { create, remove, set, set_loading, update } from '../store/devices';
import { normalizeDevice } from '../normalizers/device';

export const GET_DEVICE = 'saga/device/get';
export const GET_DEVICES = 'saga/devices/get';
export const DELETE_DEVICE = 'saga/devices/delete';
export const PUT_DEVICE = 'saga/devices/put';
export const POST_DEVICE = 'saga/devices/post';

interface GetDevice {
    type: typeof GET_DEVICE;
    payload: IDevice['id'];
}

interface GetDevices {
    type: typeof GET_DEVICES;
    payload: {
        page?: number;
        pageSize?: number;
    };
}

interface PutDevice {
    type: typeof PUT_DEVICE;
    payload: IDevice;
}

interface PostDevice {
    type: typeof POST_DEVICE;
    payload: IDevice;
}

interface DeleteDevice {
    type: typeof DELETE_DEVICE;
    payload: IDevice['id'];
}

export function* getItem(action: GetDevice): Generator {
    const abortController = new AbortController();

    yield put(set_loading(true));

    try {
        const item = (yield call(api, `/api/devices/${action.payload}.json`, {
            signal: abortController.signal,
        })) as IDevice;

        yield put(update({ byId: { [item.id]: item }, entities: [item.id] }));
    } catch (e) {
        yield put(alert((e as Error).message));
    } finally {
        if (yield cancelled()) {
            yield abortController.abort();
        }

        yield put(set_loading(false));
    }
}

export function* getCollection(action: GetDevices): Generator {
    const abortController = new AbortController();

    yield put(set_loading(true));

    try {
        const queryParams = yield call(stringify, action.payload);
        const response = (yield call(api, `/api/devices.jsonld?${queryParams}`, {
            signal: abortController.signal,
        })) as IJsonLdCollection<IDevice>;
        const devices = (yield call(normalizeDevice, response['hydra:member'])) as NormalizedEntities<IDevice>;

        yield put(
            set({
                byId: devices.entities.byId,
                entities: devices.result,
                count: response['hydra:totalItems'],
            })
        );
    } catch (e) {
        yield put(alert((e as Error).message));
    } finally {
        if (yield cancelled()) {
            yield abortController.abort();
        }

        yield put(set_loading(false));
    }
}

export function* deleteItem(action: DeleteDevice): Generator {
    yield put(set_loading(true));

    try {
        yield call(api, `/api/devices/${action.payload}.json`, {
            method: 'DELETE',
        });

        yield put(remove([action.payload]));
    } catch (e) {
        yield put(alert((e as Error).message));
    } finally {
        yield put(set_loading(false));
    }
}

export function* putItem(action: PutDevice): Generator {
    yield put(set_loading(true));
    try {
        const response = (yield call(api, `/api/devices/${action.payload.id}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(action.payload),
        })) as JsonLdObject<IDevice>;

        const normalizedDevice = (yield call(normalizeDevice, response)) as NormalizedEntities<IDevice>;

        yield put(
            update({
                byId: normalizedDevice.entities.byId,
                entities: normalizedDevice.result,
            })
        );

        yield put(alert({ message: 'device.list.updated', type: 'success' }));
    } catch (e) {
        yield put(alert((e as Error).message));
    } finally {
        yield put(set_loading(false));
    }
}

export function* postItem(action: PostDevice): Generator {
    yield put(set_loading(true));
    try {
        const response = (yield call(api, `/api/devices`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(action.payload),
        })) as JsonLdObject<IDevice>;

        yield put(
            create({
                byId: { [response.id]: response },
                entities: [response.id],
                lastId: response.id,
            })
        );

        yield put(alert({ message: 'device.list.created', type: 'success' }));
    } catch (e) {
        yield put(alert((e as Error).message));
    } finally {
        yield put(set_loading(false));
    }
}

function* deviceSaga(): Generator {
    yield takeLatest(GET_DEVICE, getItem);
    yield takeLatest(GET_DEVICES, getCollection);
    yield takeEvery(DELETE_DEVICE, deleteItem);
    yield takeEvery(PUT_DEVICE, putItem);
    yield takeEvery(POST_DEVICE, postItem);
}

export default deviceSaga;
