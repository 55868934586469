import { createSlice } from '@reduxjs/toolkit';
import { IDeviceList } from '../types';
import type { IBaseState } from './actions';
import * as actions from './actions';

const initialState: IBaseState<IDeviceList> = {
    byId: {},
    entities: [],
    loading: false,
    count: 0,
};

export const deviceList = createSlice({
    name: 'device-list',
    initialState,
    reducers: {
        ...actions,
    },
});

export const { set_loading, set, update, remove } = deviceList.actions;

export default deviceList.reducer;
