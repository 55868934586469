import { ApiGenericError } from '../utils';
import { Entity } from './utils';

export interface ConflictData<T extends Entity> {
    byProperty: { [P in keyof Partial<T>]: { current: T[P]; change: T[P] } };
    property: (keyof Partial<T>)[];
}

export interface ConstraintViolation {
    propertyPath: string;
    message: string;
    code: string;
    index?: number;
}

export type ConstraintViolationListError = {
    violations: ConstraintViolation[];
} & (ApiJsonError | ApiJsonLdError);

export type ApiError = ApiJsonError | ApiJsonLdError | ConstraintViolationListError;

export interface ApiJsonError {
    title: string;
    detail: string;
}

export interface ApiJsonLdError {
    '@context': string;
    '@type': string;
    'hydra:title': string;
    'hydra:description': string;
}

export const isConstraintViolationListError = (error: object): error is ConstraintViolationListError =>
    isApiError(error) && typeof (error as ConstraintViolationListError).violations !== 'undefined';

export const isApiError = (error: object): error is ApiError => isApiJsonError(error) || isApiJsonLdError(error);

export const isApiJsonError = (error: object): error is ApiJsonError =>
    typeof (error as ApiJsonError).title !== 'undefined' && typeof (error as ApiJsonError).detail !== 'undefined';

export const isApiJsonLdError = (error: object): error is ApiJsonLdError =>
    typeof (error as ApiJsonLdError)['hydra:title'] !== 'undefined' &&
    typeof (error as ApiJsonLdError)['hydra:description'] !== 'undefined';

/**
 * Aggregate type for generic JavaScript error and our custom errors.
 */
export type Exception = ApiGenericError | Error;
