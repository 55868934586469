import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { IConfiguration } from '../types';
import { pushAt } from '../utils';
import type { IBaseState } from './actions';
import * as actions from './actions';

export interface IConfigurationWithError extends IConfiguration {
    error?: string;
}

const initialState: IBaseState<IConfigurationWithError> = {
    entities: [],
    byId: {},
    count: 0,
    loading: false,
};

export const configuration = createSlice({
    name: 'configuration',
    initialState,
    reducers: {
        ...actions,
        update: (
            state: IBaseState<IConfigurationWithError>,
            action: PayloadAction<{
                byId: Record<IConfigurationWithError['id'], Partial<IConfigurationWithError>>;
                entities: IConfigurationWithError['id'][];
            }>
        ) => {
            // Acknowledge previous errors, keep the new one.
            action.payload.byId = action.payload.entities.reduce((prev, current) => {
                if (typeof action.payload.byId[current].error !== 'undefined') {
                    return {
                        ...prev,
                        [current]: action.payload.byId[current],
                    };
                }

                return {
                    ...prev,
                    [current]: {
                        ...action.payload.byId[current],
                        error: undefined,
                    },
                };
            }, {});

            return actions.update(state, action);
        },
        add_configuration: (
            state: IBaseState<IConfigurationWithError>,
            action: PayloadAction<{
                position?: number;
                configuration: IConfigurationWithError;
            }>
        ) => {
            state.byId = {
                ...state.byId,
                [action.payload.configuration.id]: action.payload.configuration,
            };

            state.entities =
                typeof action.payload.position === 'undefined'
                    ? [...state.entities, action.payload.configuration.id]
                    : pushAt(state.entities, action.payload.position, action.payload.configuration.id);
            state.count = state.count + 1;
        },
        reset: () => {
            return initialState;
        },
    },
});
// Action creators are generated for each case reducer function
export const { set_loading, set, update, add_configuration, reset } = configuration.actions;

export default configuration.reducer;
