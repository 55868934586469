/**
 * Trigger a download for the file at the given url, using the given filename.
 * @see https://stackoverflow.com/questions/45831191/generate-and-download-file-from-js
 */
export const download = async (url: string, filename?: string) => {
    const response = await fetch(url, {
        credentials: 'include',
    });

    const blob = await response.blob();
    const href = URL.createObjectURL(blob);

    const element = document.createElement('a');

    element.setAttribute('href', href);
    element.setAttribute('download', filename ?? '');
    element.style.display = 'none';

    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);
    window.URL.revokeObjectURL(href);
};

/**
 * Converts a file size in bytes into an human readable format (kB, MB, GB)
 */
export const bytesToSize = (bytes: number, decimals = 2): string => {
    if (bytes === 0) {
        return '0 Bytes';
    }

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
};
