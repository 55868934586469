// istanbul ignore file
// This file cannot be tested as it only loads global stylesheet through material-ui.
import { useTheme, GlobalStyles as MuiGlobalStyles } from '@mui/material';

export const GlobalStyles = (): React.ReactElement => {
    const theme = useTheme();
    return (
        <MuiGlobalStyles
            styles={{
                '*': {
                    boxSizing: 'border-box',
                    margin: 0,
                    padding: 0,
                },
                html: {
                    WebKitFontSmoothing: 'antialiased',
                    MozOsxFontSmoothing: 'grayscale',
                    height: '100%',
                    width: '100%',
                },
                body: {
                    height: '100%',
                    width: '100%',
                },
                '#root': {
                    height: '100%',
                    width: '100%',
                },
                kbd: {
                    border: `1px solid ${theme.palette.divider}`,
                    borderRadius: theme.shape.borderRadius,
                    backgroundColor: theme.palette.background.dark,
                    fontFamily: theme.typography.caption.fontFamily,
                    fontSize: theme.typography.caption.fontSize,
                    color: theme.typography.caption.color,
                    padding: '3px 5px',
                    boxShadow: theme.shadows[1],
                },
            }}
        />
    );
};
